import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@utils/date-utils';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';

@Component({
  selector: 'app-event-info',
  standalone: true,
  imports: [CommonModule, TranslateModule, SafeHtmlPipe],
  templateUrl: './event-info.component.html',
  styleUrl: './event-info.component.scss',
})
export class EventInfoComponent {
  @Input() event?: Event;
  @Input() isEventThemeColor?: boolean = true;
  @Input() bodyColor?: string;
  @Input() showDescription: boolean = true;
  @Input() dateTimeNewRow: boolean = false;
  currentLang?: string;

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(() => {
      this.currentLang = this.translateService.currentLang;
    });
    this.currentLang = this.translateService.currentLang;
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  protected readonly EventType = EventType;
  protected readonly DateUtils = DateUtils;
}
