import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgForOf } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTab } from '@angular/material/tabs';
import { CreateEventTicketOrderRefundRequest } from '@models/events/dto/create-event-ticket-order-refund.request';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { Event } from '@models/events/event.model';
import { RefundReason } from '@models/tickets/refund-reason.enum';
import { User } from '@models/users/user.model';
import { InviteEmailComponent } from '@modules/events/dialogs/invite/invite-email/invite-email.component';
import { InviteShareOptionsComponent } from '@modules/events/dialogs/invite/invite-share-options/invite-share-options.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  standalone: true,
  imports: [
    InviteEmailComponent,
    InviteShareOptionsComponent,
    MatDialogContent,
    MatTab,
    TranslateModule,
    ReactiveFormsModule,
    MatFormField,
    CdkTextareaAutosize,
    MatInput,
    MatSuffix,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
  ],
  templateUrl: './request-refund.dialog.html',
  styleUrl: './request-refund.dialog.scss',
  providers: [EventService, NotificationService, TranslateService],
})
export class RequestRefundDialog {
  form?: FormGroup;
  event?: Event;
  order?: EventAttendeeTicketOrder;
  loggedUser?: User;
  step: number = 1;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RequestRefundDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    if (data) {
      this.event = data.event;
      this.order = data.order;
      this.loggedUser = data.loggedUser;
    }
    this.form = this.fb.group({
      refundReason: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  sendRefundRequest() {
    this.form?.markAsTouched();
    const request: CreateEventTicketOrderRefundRequest = {
      reason: this.form?.controls['refundReason'].value,
      message: this.form?.controls['message'].value,
      loggedUserId: this.loggedUser?.id,
      isRequestedByAttendee:
        this.loggedUser?.id === this.order?.eventAttendee?.userId,
    };
    if (this.form?.valid && this.order) {
      this.eventService.createRefund(this.order.id, request).subscribe({
        next: (res) => {
          this.notificationService.success(
            this.translateService.instant(
              'APP.REQUEST_REFUND.REFUND_SUCCESS_MESSAGE',
            ),
          );
          this.close();
        },
        error: (err) => {
          this.notificationService.error(err.message);
        },
      });
    }
  }

  changeRefundReason(reason: any) {
    this.form?.controls['refundReason'].setValue(reason.value);
  }

  goNext() {
    this.form?.markAsTouched();
    if (this.form?.valid && this.order) {
      this.step++;
    }
  }

  goBack() {
    this.step--;
  }

  close() {
    this.dialogRef.close();
  }

  protected readonly RefundReason = RefundReason;
}
