@if(ticket){
  <div class="ticket"
       [class.not-empty]='ticketQuantity && ticketQuantity > 0'
       [class.sold-out]='isSoldOut'
       [class.squared-style]='squaredStyle'
       [class.hidden]='isMinAmountBiggerThanVoucherLimit() || !ticket.visible'>
    <div class='header'>
      <div class="ticket-info">
        <div class="type">{{ ticket.name }}</div>
      </div>

        <div class="ticket-quantity-wrapper" [class.squaredStyle]='squaredStyle'>
          @if(ticketQuantity){
          <div class="action-btn" (click)="onRemove()">
            <i class="far fa-minus"></i>
          </div>
          <div class="display">{{ ticketQuantity }}</div>
      }
        <div class="action-btn" (click)="onAdd()" [class.disabled]='!checkTicketAddValid(ticket) || isBeforeStartSale() || isLimitReached'>
          <i class="far fa-plus"></i>
        </div>
      </div>
    </div>

    <div class='price-wrapper'>
        <div class='sale-info'>
          @if(ticket.showSalesDeadlineAndStatusDuringOrder){
            @if(isBeforeStartSale()){
              <div class='normal'>
                {{ "APP.TICKETING.SALE_STARTS_ON" | translate }} {{ ticket.saleStartDate | date: 'MMM d, y' }}
              </div>
            } @else if (isSoldOut){
              <div class='highlighted'>
                {{ "APP.TICKETING.SOLD_OUT" | translate }}
              </div>
            }
            @else if(ticket.getNumAvailableTickets() < 10){
              <div class='highlighted'>
                {{ "APP.TICKETING.FEW_TICKETS_LEFT" | translate }}
              </div>
            }

            @else if(ticket.saleEndDate){
              <div class='normal'>
                {{ "APP.TICKETING.SALE_ENDS_ON" | translate }} {{ ticket.saleEndDate | date: 'MMM d, y' }}
              </div>
            }
          }
        </div>

      <div class="price">
        @if(appliedDiscount){
          <div class='price-with-discount-wrapper'>
            <div class='price'>
              <del class='old-price'>{{ ticket.getDisplayPrice() }}</del>
              {{ calculateDiscount() }}
            </div>
            <div class='discount-applied-wrapper' [class.round-style]='!squaredStyle'>
              <i class="far fa-tag"></i>
              <span>{{ "APP.VOUCHERS.DISCOUNT_APPLIED" | translate }}</span>
            </div>
          </div>
        }@else{
          {{ ticket.feeType === TicketFeeType.PAID ? ticket.getDisplayPrice() : '' }}
        }
      </div>
    </div>
    <div class='description-wrapper' [class.expanded]='isExpanded'>
      <div class='description-text'>
        {{ ticket.description }}
      </div>
      @if(ticket.description.length > 0){
        <a href="javascript:void(0);" class="read-more" (click)="toggleReadMore()">
          @if(isExpanded){
            {{ 'APP.TICKETING.READ_LESS' | translate }}
          }@else{
            {{ 'APP.TICKETING.READ_MORE' | translate }}
          }
        </a>
      }

    </div>
  </div>
}
