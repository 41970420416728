import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventAttendeeTicketOrderRefund } from '@models/events/event-attendee-ticket-order-refund.model';
import { EventAttendeeTicketOrderRefundStatus } from '@models/events/event-attendee.ticket-order-refund-status.enum';
import { User } from '@models/users/user.model';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { TextSizeDirective } from '../../../../directives/text-size.directive';

@Component({
  selector: 'app-qr-data-card',
  standalone: true,
  imports: [
    QRCodeModule,
    TranslateModule,
    DatePipe,
    UserAvatarComponent,
    TextSizeDirective,
  ],
  templateUrl: './qr-data-card.component.html',
  styleUrl: './qr-data-card.component.scss',
})
export class QrDataCardComponent {
  @Input() logo?: string;
  @Input() qrLogo?: string;
  @Input() qrData?: string;
  @Input() data?: string;
  @Input() header?: string;
  @Input() footer?: string;
  @Input() isReferral?: boolean = false;
  @Input() users?: User[];
  @Input() loggedUser?: User;
  @Input() isBackSide: boolean = false;
  @Input() index?: number;
  @Input() allowFlip?: boolean = true;
  @Input() refund?: EventAttendeeTicketOrderRefund;
  @Input() brandColors?: boolean = true;
  @Output() onAction = new EventEmitter();

  flipCard() {
    if (this.allowFlip) {
      if (this.isReferral && this.users?.length === 0) {
        this.isBackSide = false;
      } else {
        this.isBackSide = !this.isBackSide;
      }
    }
  }

  executeAction() {
    this.onAction.emit();
  }

  protected readonly EventAttendeeTicketOrderRefundStatus =
    EventAttendeeTicketOrderRefundStatus;
}
