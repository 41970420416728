@if (event) {
  <div class="tickets-selection-wrapper">
      <div>
        <div class="title">
          {{
            "APP.TICKETING.WHEN_WOULD_YOU_LIKE_TO_ATTEND_THE_EVENT" | translate
          }}
        </div>
      </div>

    <div class="ticket-selection-content">
        <div class="days-wrapper" [class.round-style]='event.type !== EventType.ADVANCED_ONE_COLUMN'>
          <div
            class="day-button all-days"
            [class.selected]="allDays"
            (click)="selectAllDays()"
          >
            {{ "APP.ALL_DAYS" | translate }}
          </div>
          @for (date of getEventDatesWithTickets(); track $index) {
            <div
              class="day-button"
              [class.selected]="selectedDay?.getDate() === date.getDate()"
              (click)="selectDate(date)"
            >
              <span>{{ "APP.DAY" | translate }} {{ $index + 1 }}</span>
              <span class="date">{{ moment(date).format("DD.MM") }}</span>
            </div>
          }
        </div>

      @if (!isDesktop) {
        @if (form) {
          <form [formGroup]="form" class="voucher-form">
            <mat-form-field appearance="fill" class="w-100" [class.rounded-style]='event.type !== EventType.ADVANCED_ONE_COLUMN'>
              <mat-label class="placeholder">{{
                "APP.VOUCHERS.VOUCHER_CODE" | translate
              }}</mat-label>
              <input formControlName="voucherCode" type="text" matInput />
              @if (voucherApplied) {
                <div matSuffix class="suffix-actions">
                  <div class="success-code">
                    <i class="fas fa-check-circle"></i>
                  </div>
                  <div
                    class="remove-button"
                    [matTooltip]="'APP.REMOVE' | translate"
                    (click)="removeCode()"
                  >
                    <i class="far fa-trash"></i>
                  </div>
                </div>
              } @else {
                <div matSuffix class="suffix-actions">
                  @if (unrecognizedCode) {
                    <div class="danger-code">
                      <i class="fas fa-minus-circle"></i>
                    </div>
                  } @else {
                    <div
                      matSuffix
                      class="apply-button"
                      [matTooltip]="'APP.APPLY' | translate"
                      (click)="applyCode()"
                    >
                      {{ "APP.APPLY" | translate }}
                    </div>
                  }
                </div>
              }
              @if (unrecognizedCode) {
                <mat-hint class="code-hint danger text">{{
                  "APP.VOUCHERS.ERRORS.UNRECOGNIZED_CODE" | translate
                }}</mat-hint>
              }
              @if (voucherApplied) {
                <mat-hint class="code-hint text">
                  {{ voucherApplied.codeName }}
                  {{ "APP.VOUCHERS.APPLIED" | translate }}</mat-hint
                >
              }
            </mat-form-field>
          </form>
        }
      }
        <div class="ticket-wrapper">
          @for (ticket of filteredTickets; track ticket.id) {
            <app-event-ticket-selection-view
              [isSoldOut]="checkIfSoldOut(ticket)"
              [ticket]="ticket"
              [squaredStyle]='event.type === EventType.ADVANCED_ONE_COLUMN'
              [orderTickets]="orderTickets"
              [ticketsWithAppliedDiscount]="ticketsWithAppliedDiscount"
              [appliedDiscount]="checkIfDiscountApplied(ticket)"
              [appliedVoucher]="voucherApplied"
              [ticketQuantity]="ticket.id ? getTicketQuantity(ticket.id) : 0"
              [currentTicketQuantity]="currentTicketQuantity"
              (addTicket)="addTicket($event)"
              (removeTicket)="removeTicket($event)"
            />
          }
        </div>

    </div>

    @if (!isDesktop) {
      <app-footer [showOptions]="false" [includePoweredBy]="true" />
    }
  </div>
  <div class="footer">
    <div class="footer-content">
      <div class="total">
        {{ "APP.TICKETING.SUM" | translate }}
        <span class="price"
          >{{
            NumberUtils.roundToUpTo2Decimals(fullPrice / 100.0)
          }}
          &#8364;</span
        >
      </div>
      <div class="actions">
        @if (isDesktop) {
          @if (form) {
            <form [formGroup]="form" class="voucher-form">
              <mat-form-field appearance="fill" class="w-100" [class.rounded-style]='event.type !== EventType.ADVANCED_ONE_COLUMN'>
                <mat-label class="placeholder">{{
                  "APP.VOUCHERS.VOUCHER_CODE" | translate
                }}</mat-label>
                <input
                  formControlName="voucherCode"
                  type="text"
                  matInput
                  placeholder="Voucher code"
                />
                @if (voucherApplied) {
                  <div matSuffix class="suffix-actions">
                    <div class="success-code">
                      <i class="fas fa-check-circle"></i>
                    </div>
                    <div
                      class="remove-button"
                      [matTooltip]="'APP.REMOVE' | translate"
                      (click)="removeCode()"
                    >
                      <i class="far fa-trash"></i>
                    </div>
                  </div>
                } @else {
                  <div matSuffix class="suffix-actions">
                    @if (unrecognizedCode) {
                      <div class="danger-code">
                        <i class="fas fa-minus-circle"></i>
                      </div>
                    } @else {
                      <div
                        matSuffix
                        class="apply-button"
                        [matTooltip]="'APP.APPLY' | translate"
                        (click)="applyCode()"
                      >
                        {{ "APP.APPLY" | translate }}
                      </div>
                    }
                  </div>
                }
                @if (unrecognizedCode) {
                  <mat-hint class="code-hint danger text">{{
                    "APP.VOUCHERS.ERRORS.UNRECOGNIZED_CODE" | translate
                  }}</mat-hint>
                }
                @if (voucherApplied) {
                  <mat-hint class="code-hint text">
                    {{ voucherApplied.codeName }}
                    {{ "APP.VOUCHERS.APPLIED" | translate }}</mat-hint
                  >
                }
              </mat-form-field>
            </form>
          }
        }
        <button
          class="btn action"
          (click)="goPay()"
          [disabled]="checkoutCreationLoading()"
          [class.spinner]="checkoutCreationLoading()"
          [class.squared-style]='event.type === EventType.ADVANCED_ONE_COLUMN'
        >
            {{ "APP.TICKETING.CHECKOUT" | translate }}
        </button>
        @if (isDesktop) {
          <div class="footer-powered-by-cont">
            <span>{{ "APP.POWERED_BY" | translate }}</span>
            <a class="footer-icon-a" routerLink="/">
              <app-event-page-logo class="w-100" />
            </a>
          </div>
        }
      </div>
    </div>
  </div>
}
