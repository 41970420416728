<div class="checkout-wrapper">
  <div class="header">
    <div class="title">
      {{ "APP.EVENT_PAYMENT.THANK_YOU_FOR_YOUR_ORDER" | translate }}
    </div>
    <div class="description">
      {{ "APP.EVENT_PAYMENT.YOU_PARTICIPATE" | translate }}
    </div>
    <div class="close-button" (click)="close()">
      <i class="fal fa-times-circle"></i>
    </div>
  </div>
  <div class="content">
    @if (!isDesktop) {
      <div class="add-to-wallet-actions">
        <div class="google-wallet" (click)="addToWallet()">
          <img src="assets/add_to_google_wallet.svg" />
        </div>
        <div class="apple-wallet" (click)="addToWallet()">
          <img src="assets/add-to-apple-wallet.svg" />
        </div>
      </div>
      <div class="action">
        <button class="btn btn-primary-outline" (click)="downloadTicketPdf()">
          {{ "APP.EVENT_PAYMENT.DOWNLOAD_TICKET" | translate }}
        </button>
      </div>
    }
    <div class="order">
      <div class="image">
        <img
          class="key-visual-1"
          [src]="event?.mainPictureUrl || visual?.keyVisual1?.url"
        />
      </div>
      <div class="order-info">
        <div class="event-name">
          {{ event?.name }}
        </div>
        <div class="date-location">
          <div class="location item">
            <div class="title">{{ "APP.LOCATION" | translate }}</div>
            <div class="value">{{ event?.eventAddress?.locality }}</div>
          </div>
        </div>
        <app-order-overview
          [showTitle]="false"
          [showImage]="false"
          [showOrder]="false"
          [ticketOrder]="ticketOrder"
          [mainImage]="mainImage"
          [disableMobileView]="true"
        />
      </div>
    </div>
  </div>
  <div class="download-info">
    {{ "APP.EVENT_PAYMENT.DOWNLOAD_INSTRUCTIONS" | translate }}
  </div>
  @if (isDesktop) {
    <div class="add-to-wallet-actions">
      <div class="google-wallet" (click)="addToWallet()">
        <img src="assets/add_to_google_wallet.svg" />
      </div>
      <div class="apple-wallet" (click)="addToWallet()">
        <img src="assets/add-to-apple-wallet.svg" />
      </div>
    </div>
    <div class="action">
      <button class="btn btn-primary-outline" (click)="downloadTicketPdf()">
        {{ "APP.EVENT_PAYMENT.DOWNLOAD_TICKET" | translate }}
      </button>
    </div>
  }
</div>
@if (!isDesktop) {
  <app-footer [showOptions]="false" [includePoweredBy]="true" class="footer" />
}
