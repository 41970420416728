<div
  class="referral-card-container"
  (click)='flipCard()'
  [class.back-side]="isBackSide"
>
  <div class="referral-card-inner">
    <div class="referral-card referral-card-front">
      <div class="referral-card-info card-info-top">
        {{ header }}
        @if(index){
          #{{index+1}}
        }
      </div>
      <div class="referral-card-body" [class.event-colors]='!brandColors'>
        @if(logo){
          <img [src]="logo" alt="website logo" class="logo-img" />
        }

        @if(refund){
          <div class='refund-wrapper'>
            <div class='pill'>
              <i class="fas fa-hourglass-start"></i>
              <div>
                {{ EventAttendeeTicketOrderRefundStatus.getStatusName(refund.status) | translate }}
              </div>
            </div>
            <div class='status'>
              {{ EventAttendeeTicketOrderRefundStatus.getStatusDescription(refund.status) | translate }}
            </div>
            <div class='action'>
              <button class='btn btn-primary' (click)='executeAction()'>{{ "APP.REQUEST_REFUND.CONTACT_HOST" | translate }}</button>
            </div>

          </div>
        }

        <div class="referral-code-cont">
          @if(qrData){
            <qrcode
              [qrdata]="qrData || ''"
              [allowEmptyString]="true"
              [elementType]="'canvas'"
              [errorCorrectionLevel]="'M'"
              [margin]="2"
              [scale]="1"
              [width]="175"
              [imageSrc]="qrLogo"
              [imageHeight]="43"
              [imageWidth]="43"
            ></qrcode>
          }
          @if(data){
            <div class="referral-code" appTextSize [size]='"small"'>{{ data }}</div>
          }
        </div>

        <div class="referral-card-body-placeholder"></div>
      </div>

      <div class="referral-card-info card-info-bottom">
        {{ footer }}
      </div>
    </div>

    <div class="referral-card referral-card-back">
      @if(isReferral){
        @if(loggedUser){
          <div class="referral-card-info card-info-top">
            {{ "APP.REFERRAL_INVITE.INVITE_FROM" | translate }}
            {{ loggedUser.name }}
          </div>
        }
        <div class="referral-card-body"  [class.event-colors]='!brandColors'>
          <img [src]="logo" alt="website logo" class="logo-img" />

          <div class="referral-card-title">
            {{ users?.length }}
            {{ "APP.REFERRAL_INVITE.REFERRALS" | translate }}
          </div>

          <div class="referred-users">
            @for (referredUser of users; track referredUser.id) {
              <div class="referred-user">
                <app-user-avatar
                  class="user-avatar"
                  [user]="referredUser"
                  [size]="40"
                  [placeholderFontSize]="14"
                ></app-user-avatar>
                <div class="referred-user-info">
                  <div class="referred-user-name">
                    {{ referredUser.name }}
                  </div>
                  <div class="referred-user-join-date">
                    {{ "APP.REFERRAL_INVITE.JOINED" | translate }}:
                    {{ referredUser.createdAt | date: "dd.MM.yyyy" }}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
      @else{
        <div class="referral-card-info card-info-top">
          {{ header }}
          @if(index){
            #{{index+1}}
          }
        </div>
        <div class="referral-card-body">
          <p>Here should be the back side of ticket</p>
        </div>
      }

      <div class="referral-card-info card-info-bottom">
        {{ footer }}
      </div>
    </div>
  </div>
</div>
