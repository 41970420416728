export enum RefundReason {
  EVENT_CANCELLATION = 'Event Cancellation',
  EVENT_POSTPONEMENT = 'Event Postponement',
  EVENT_MISREPRESENT = 'Event Misrepresentation',
  PROGRAM_CHANGES = 'Program Changes',
  SCHEDULE_CONFLICT = 'Schedule Conflict',
  HEALTH_ISSUES = 'Health Issues',
  FAMILY_EMERGENCY = 'Family Emergency',
  DUPLICATE_BOOKING = 'Duplicate Booking',
  WRONG_TICKET_PURCHASE = 'Wrong Ticket Purchase',
  OVERCHARGE = 'Overcharge',
  TRAVEL_ISSUES = 'Travel Issues',
  ACCESSIBILITY_PROBLEMS = 'Accessibility Problems',
  POOR_CUSTOMER_SERVICE = 'Poor Customer Service',
  UNFULFILLED_PROMISES = 'Unfulfilled Promises',
  WEATHER_CONDITIONS = 'Weather Conditions',
  FORCE_MAJEURE = 'Force Majeure',
}

export namespace RefundReason {
  export function getAllRefundReasons(): RefundReason[] {
    return [
      RefundReason.EVENT_CANCELLATION,
      RefundReason.EVENT_POSTPONEMENT,
      RefundReason.EVENT_MISREPRESENT,
      RefundReason.PROGRAM_CHANGES,
      RefundReason.SCHEDULE_CONFLICT,
      RefundReason.HEALTH_ISSUES,
      RefundReason.FAMILY_EMERGENCY,
      RefundReason.DUPLICATE_BOOKING,
      RefundReason.WRONG_TICKET_PURCHASE,
      RefundReason.OVERCHARGE,
      RefundReason.TRAVEL_ISSUES,
      RefundReason.ACCESSIBILITY_PROBLEMS,
      RefundReason.POOR_CUSTOMER_SERVICE,
      RefundReason.UNFULFILLED_PROMISES,
      RefundReason.WEATHER_CONDITIONS,
      RefundReason.FORCE_MAJEURE,
    ];
  }

  export function getDisplayName(reason?: RefundReason): string {
    switch (reason) {
      case RefundReason.EVENT_CANCELLATION:
        return 'APP.REFUND_REASONS.EVENT_CANCELLATION';
      case RefundReason.EVENT_POSTPONEMENT:
        return 'APP.REFUND_REASONS.EVENT_POSTPONEMENT';
      case RefundReason.EVENT_MISREPRESENT:
        return 'APP.REFUND_REASONS.EVENT_MISREPRESENT';
      case RefundReason.PROGRAM_CHANGES:
        return 'APP.REFUND_REASONS.PROGRAM_CHANGES';
      case RefundReason.SCHEDULE_CONFLICT:
        return 'APP.REFUND_REASONS.SCHEDULE_CONFLICT';
      case RefundReason.HEALTH_ISSUES:
        return 'APP.REFUND_REASONS.HEALTH_ISSUES';
      case RefundReason.FAMILY_EMERGENCY:
        return 'APP.REFUND_REASONS.FAMILY_EMERGENCY';
      case RefundReason.DUPLICATE_BOOKING:
        return 'APP.REFUND_REASONS.DUPLICATE_BOOKING';
      case RefundReason.WRONG_TICKET_PURCHASE:
        return 'APP.REFUND_REASONS.WRONG_TICKET_PURCHASE';
      case RefundReason.OVERCHARGE:
        return 'APP.REFUND_REASONS.OVERCHARGE';
      case RefundReason.TRAVEL_ISSUES:
        return 'APP.REFUND_REASONS.TRAVEL_ISSUES';
      case RefundReason.ACCESSIBILITY_PROBLEMS:
        return 'APP.REFUND_REASONS.ACCESSIBILITY_PROBLEMS';
      case RefundReason.POOR_CUSTOMER_SERVICE:
        return 'APP.REFUND_REASONS.POOR_CUSTOMER_SERVICE';
      case RefundReason.WEATHER_CONDITIONS:
        return 'APP.REFUND_REASONS.WEATHER_CONDITIONS';
      case RefundReason.FORCE_MAJEURE:
        return 'APP.REFUND_REASONS.FORCE_MAJEURE';
      case RefundReason.UNFULFILLED_PROMISES:
        return 'APP.REFUND_REASONS.UNFULFILLED_PROMISES';
      default:
        return '';
    }
  }
}
