@if (event) {
  <div
    class="event-content"
    [class.brand-colors]="!isEventThemeColor && !bodyColor"
  >
    <div
      class="event-name primary-font"
      [ngStyle]="{
        color: bodyColor
      }"
      [style.font-weight]="
        font?.primaryFontWeight ? font?.primaryFontWeight : null
      "
    >
      {{ event.name }}
    </div>
    <div
      class="event-date-loc-info body-font"
      [ngStyle]="{
        color: bodyColor
      }"
    >
      @if (event.startDate) {
        @if(!dateTimeNewRow){
          <div class="event-date-cont">
            <div class="event-date event-info-with-icon">
              <i class="far fa-calendar-day"></i>
              <span>{{
                  DateUtils.formatDateDay(event.startDate, currentLang) +
                  "., " +
                  (event.startDate | date: "dd.MM.yyyy")
                }}</span>
            </div>
            <div class="event-time event-info-with-icon">
              <i class="far fa-clock"></i>
              <span>{{ event.startDate | date: "HH:mm" }}</span>
              @if (event.endsOnSameDate()) {
                <span>- {{ event.endDate | date: "HH:mm" }}</span>
              }
            </div>
            @if (event.endDate && !event.endsOnSameDate()) {
              <span>-</span>
            }
            @if (event.endDate && !event.endsOnSameDate()) {
              <div class="event-date-cont">
                <div class="event-date event-info-with-icon">
                  <i class="far fa-calendar-day"></i>
                  <span>{{
                      DateUtils.formatDateDay(event.endDate, currentLang) +
                      "., " +
                      (event.endDate | date: "dd.MM.yyyy")
                    }}</span>
                </div>
                <div class="event-time event-info-with-icon">
                  <i class="far fa-clock"></i>
                  <span>{{ event.endDate | date: "HH:mm" }}</span>
                </div>
              </div>
            }
          </div>
        }@else{
          <div class="event-date-cont one-row-date">
            <div class="event-date event-info-with-icon">
              <i class="far fa-calendar-day"></i>
              <span>{{
                  DateUtils.formatDateDay(event.startDate, currentLang) +
                  "., " +
                  (event.startDate | date: "dd.MM.yyyy")
                }}</span>
              @if (event.endDate && !event.endsOnSameDate()) {
                  <span> - {{
                      DateUtils.formatDateDay(event.endDate, currentLang) +
                      "., " +
                      (event.endDate | date: "dd.MM.yyyy")
                    }}</span>
              }
            </div>
            <div class='time-wrapper'>
              <div class="event-time event-info-with-icon">
                <span>{{ event.startDate | date: "HH:mm" }}</span>
                    <span>- {{ event.endDate | date: "HH:mm" }}</span>
              </div>
            </div>
          </div>
        }

      } @else {
        <div class="event-date-cont">
          <div class="event-date event-info-with-icon">
            <i class="far fa-calendar-day"></i>
            <span>{{ "APP.EVENT.DATE_TBD" | translate }}</span>
          </div>
        </div>
      }
      @if (event.hasAddress()) {
        <a
          class="event-address event-info-with-icon"
          [class.smaller-font]='dateTimeNewRow'
          [ngStyle]="{
            color: bodyColor
          }"
          [href]="event.getGoogleMapsLink()"
          target="_blank"
        >
          <i class="far fa-map-pin"></i>
          <span>{{ event.getAddressDisplayName() }}</span>
        </a>
      } @else {
        <div class="event-date-cont">
          <div
            class="event-address event-info-with-icon"
            [class.smaller-font]='dateTimeNewRow'
            [ngStyle]="{
              color: bodyColor
            }"
          >
            <i class="far fa-map-pin"></i>
            <span>{{ "APP.EVENT.LOCATION_TBD" | translate }}</span>
          </div>
        </div>
      }
    </div>

    @if(showDescription){
      @if (!event.type || event.type === EventType.PARTYPAGE) {
        <div
          class="body-text body-font"
          [innerHtml]="event.description | safeHtml"
          [ngStyle]="{
          color: bodyColor
        }"
        ></div>
      } @else {
        <div
          class="body-text body-font"
          [innerHtml]="event.description | safeHtml"
          [ngStyle]="{
          color: bodyColor
        }"
        ></div>
      }
    }

  </div>
}
