export enum EventAttendeeTicketOrderStatus {
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CAPTURE = 'requires_capture',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCEEDED = 'succeeded',
  REFUNDED = 'refunded',
}

export namespace EventAttendeeTicketOrderStatus {
  export function getDisplayName(
    status?: EventAttendeeTicketOrderStatus,
  ): string {
    switch (status) {
      case EventAttendeeTicketOrderStatus.SUCCEEDED:
        return 'APP.TICKETS_SETTINGS.ORDERS.STATUS.SUCCEEDED';
      case EventAttendeeTicketOrderStatus.REFUNDED:
        return 'APP.TICKETS_SETTINGS.ORDERS.STATUS.REFUNDED';
      default:
        return '';
    }
  }
}
