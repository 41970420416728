import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventAttendeeQuestionType } from '@models/event-attendance/event-attendee-question-type.enum';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { EventRSVPOption } from '@models/event-attendance/event-rsvp-option.model';
import { RSVPLayout } from '@models/event-attendance/rsvp-layout.enum';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import {
  EventRSVPRequest,
  RSVPGroupMemberData,
  RSVPQuestionAnswer,
} from '@models/events/dto/event-rsvp.request';
import { VerifyNoAccountRSVPRequest } from '@models/events/dto/verify-no-account-rsvp.request';
import { EventRSVPConfirmationPageSectionType } from '@models/events/event-rsvp-confirmation-page-section-type.enum';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { RSVPDialogStep } from '@models/events/rsvp-dialog-step.enum';
import { LoginRequest } from '@models/users/dto/login.request';
import { NewUserCreationRequest } from '@models/users/dto/new-user-creation.request';
import { User } from '@models/users/user.model';
import { EventRsvpQuestionComponent } from '@modules/events/components/event-rsvp-question/event-rsvp-question.component';
import { RsvpPlusOnesStepComponent } from '@modules/events/components/rsvp-plus-ones-step/rsvp-plus-ones-step.component';
import { VerifyEmailDialogComponent } from '@modules/events/dialogs/verify-email-dialog/verify-email-dialog.component';
import { VerifyPhoneDialogComponent } from '@modules/events/dialogs/verify-phone-dialog/verify-phone-dialog.component';
import { CredentialsFormButtonToggleComponent } from '@modules/shared/components/credentials-form-button-toggle/credentials-form-button-toggle.component';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { VerifyEmailStepComponent } from '@modules/sign-up/components/verify-email-step/verify-email-step.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { UserService } from '@services/auth/user.service';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { Helpers } from '@utils/helpers';
import { FormsModules } from '@utils/shared-modules';
import { AppRoutes } from 'app/routes';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs';
import { SafeHtmlPipe } from '../../../../pipes/safe-html.pipe';

@Component({
  selector: 'app-rsvp',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModules,
    MatCheckboxModule,
    TelInputComponent,
    VerifyEmailDialogComponent,
    VerifyPhoneDialogComponent,
    CredentialsFormButtonToggleComponent,
    VerifyEmailStepComponent,
    MatDialogActions,
    EventRsvpQuestionComponent,
    RsvpPlusOnesStepComponent,
    MatDialogContent,
    SafeHtmlPipe,
  ],
  providers: [
    EventService,
    NotificationService,
    UserService,
    TranslateService,
    ScreenWidthService,
  ],
  templateUrl: './rsvp.dialog.html',
  styleUrl: './rsvp.dialog.scss',
})
export class RsvpDialog implements OnInit {
  event?: Event;
  selectedRSVPType?: RSVPOptionType;
  isAttendee?: boolean;

  form?: FormGroup;

  loggedUser?: User;
  user?: User;
  userPhone?: string;
  loginRequest?: LoginRequest;

  numAttendeesOptions: number[] = [1];

  selectedOption: string = 'phone';

  step: number = 1;

  rsvpReq?: EventRSVPRequest;

  isWrongCode: boolean = false;

  otpUserId?: number;

  showPassword = false;

  onWaitlist: boolean = false;
  onPendingList: boolean = false;
  notGoing: boolean = false;
  rsvpOptions: EventRSVPOption[] | undefined = [];

  inProgress = false;
  inProgressWithoutAccount: boolean = false;

  accountWithEmailExists?: boolean;

  isNoAccountRSVPVerify = false;
  isNoAccountRSVPVerifyWrongCode = false;
  isSportPage: boolean = false;

  // invitation event
  invitedAttendeeUuid?: string;
  invitedAttendee?: EventAttendee;

  inviteeEmail?: string;
  inviteeFirstName?: string;
  inviteeLastName?: string;

  layout: RSVPLayout = RSVPLayout.THREE_SMILIES;
  formAppearance: MatFormFieldAppearance = 'outline';
  bodyColor = '#ffffff';

  isDesktop = true;

  @ViewChild('telInput') telInput?: TelInputComponent;

  constructor(
    public dialogRef: MatDialogRef<RsvpDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private eventService: EventService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
  ) {
    if (data) {
      this.event = data.event;
      this.selectedRSVPType = data.rsvpType;
      this.isAttendee = data.isAttendee;
      this.onWaitlist = data.onWaitlist;
      this.onPendingList = data.onPendingList;
      this.rsvpOptions = this.event?.rsvpOptions;
      this.notGoing = data.notGoing;

      // invitation event
      this.invitedAttendeeUuid = data.invitedAttendeeUuid;
      this.invitedAttendee = data.invitedAttendee;

      this.inviteeEmail = data.inviteeEmail;
      this.inviteeFirstName = data.inviteeFirstName;
      this.inviteeLastName = data.inviteeLastName;

      if (this.event?.hasOneButtonSignUp()) {
        this.layout = RSVPLayout.ONE_BUTTON;
      } else if (
        this.event &&
        [
          'founders-fiesta',
          'eltern-kind-olympiade-2024',
          'woerthsee-vorschulturnen',
          'eventpageai-exit-party',
        ].includes(this.event.uri)
      ) {
        this.layout = RSVPLayout.TWO_BUTTONS;
        this.formAppearance = 'fill';
        this.bodyColor = 'var(--event-theme-primary-color)';
      }
      if (
        this.event?.eventTemplate?.rsvpSettings &&
        this.event.eventTemplate.rsvpSettings.layout.toString() != ''
      ) {
        this.layout = this.event.eventTemplate.rsvpSettings.layout;
      }

      this.setRsvpDialogColors();

      this.authService.userSubject.subscribe((user) => {
        if (user.id > 0) {
          this.loggedUser = user;
        }

        if (this.event) {
          if (this.event.plusOnesLimit && this.event.plusOnesLimit > 1) {
            this.numAttendeesOptions = [
              ...Array(this.event.plusOnesLimit).keys(),
            ].map((x) => ++x);
          }

          let numAttendees = 0;

          if (this.loggedUser?.id) {
            const userAttendee = this.event.getUserAttendee(this.loggedUser.id);
            if (userAttendee) {
              numAttendees = userAttendee.getPlusOnes();
            }
          }

          this.form = this.fb.group({
            numAttendees: [
              {
                value: numAttendees,
                disabled: !this.event.plusOnesLimit,
              },
            ],
            comment: [''],
          });

          if (this.event.eventTemplate?.rsvpSettings?.requireGroupRsvp) {
            const previousRSVP = this.event.getUserAttendee(
              this.loggedUser?.id || 0,
            );
            this.form.addControl(
              'groupName',
              this.fb.control(
                previousRSVP?.groupName || '',
                Validators.required,
              ),
            );
          }

          if (this.event.eventTemplate?.attendeeQuestions) {
            for (const question of this.event.eventTemplate.attendeeQuestions) {
              this.form.addControl(
                `question#${question.id}`,
                this.fb.control(
                  '',
                  question.isRequired &&
                    question.type !== EventAttendeeQuestionType.CHECKBOX
                    ? Validators.required
                    : [],
                ),
              );
            }
          }

          if (
            this.event.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData()
          ) {
            this.form.addControl('plusOnes', this.fb.array([]));
          }

          if (!this.loggedUser) {
            let name = '';
            let email = '';
            if (this.invitedAttendeeUuid && this.invitedAttendee) {
              name = this.invitedAttendee.getName();
              email = this.invitedAttendee.getEmail();
            } else if (this.inviteeEmail) {
              email = this.inviteeEmail;
              name = this.inviteeFirstName || '';
              if (this.inviteeLastName && this.inviteeLastName !== '') {
                if (name !== '') {
                  name = name + ' ';
                }
                name = name + this.inviteeLastName;
              }
            }

            this.form.addControl(
              'name',
              this.fb.control(name, Validators.required),
            );

            const emailFormGroup = this.fb.group({
              email: [email, [Validators.required, Validators.email]],
              password: ['', [Validators.required]],
              acceptedPrivacyPolicy: [false, [Validators.requiredTrue]],
              acceptedTerms: [false, [Validators.requiredTrue]],
            });

            this.form.addControl('emailFormGroup', emailFormGroup);
            this.form.addControl(
              'phoneFormGroup',
              this.fb.group({
                phone: ['', [Validators.required]],
              }),
            );

            if (email !== '') {
              this.selectOption('email');
              this.checkUserWithEmailExists();
            } else {
              this.selectOption('phone');
            }

            this.emailFormGroup.controls['email'].valueChanges
              .pipe(
                debounceTime(250),
                distinctUntilChanged(),
                tap((val) => {
                  if (this.emailFormGroup.controls['email'].valid) {
                    this.checkUserWithEmailExists();
                  } else {
                    this.accountWithEmailExists = undefined;
                  }
                }),
              )
              .subscribe();
          }
        }
      });
    }
    this.screenWidthService.isDesktop().subscribe({
      next: (isDesktop: boolean) => {
        this.isDesktop = isDesktop;
      },
    });
  }

  ngOnInit(): void {
    this.isSportPage = this.event?.type === EventType.SPORTPAGE;
  }

  get emailFormGroup(): FormGroup {
    return this.form?.get('emailFormGroup') as FormGroup;
  }

  get phoneFormGroup(): FormGroup {
    return this.form?.get('phoneFormGroup') as FormGroup;
  }

  close(event?: any) {
    this.dialogRef.close(event);
  }

  changePhone() {
    this.selectedOption = 'phone';
    this.step = 1;
  }

  private getRsvpTextFromSettings(
    defaultText: string,
    textType: 'title' | 'description',
    rsvpType: RSVPOptionType,
  ): string {
    let result = this.translateService.instant(defaultText);

    const texts = this.event?.eventTemplate?.rsvpSettings?.texts;
    const foundText = texts?.find(
      (x) => x.step === RSVPDialogStep.RSVP && x.rsvpType === rsvpType,
    )?.[textType];

    if (foundText && foundText.trim() !== '') {
      result = foundText;
    }

    return result;
  }

  getRsvpDialogTitleWaitList(): string {
    return this.getRsvpTextFromSettings(
      'APP.WAIT_LIST.JOIN_WAIT_LIST',
      'title',
      RSVPOptionType.WAITLIST,
    );
  }

  getRsvpDialogDescriptionWaitList(): string {
    return this.getRsvpTextFromSettings(
      'APP.WAIT_LIST.WAIT_LIST_DESCRIPTION',
      'description',
      RSVPOptionType.WAITLIST,
    );
  }

  private getRsvpText(
    defaultText: string,
    textType: 'title' | 'description',
    step: RSVPDialogStep,
  ): string {
    let result = this.translateService.instant(defaultText);

    const texts = this.event?.eventTemplate?.rsvpSettings?.texts;
    const foundText = texts?.find((x) => x.step === step)?.[textType];

    if (foundText && foundText.trim() !== '') {
      result = foundText;
    }

    return `<p>${result}</p>`;
  }

  getRsvpDialogTitle(
    step: RSVPDialogStep,
    defaultText = 'APP.EVENT_RSVP.ARE_YOU_GOING',
  ): string {
    return this.getRsvpText(defaultText, 'title', step);
  }

  getRsvpDialogDescription(step: RSVPDialogStep): string {
    return this.getRsvpText(
      'APP.EVENT_RSVP.DATA_SAFE_DESCRIPTION',
      'description',
      step,
    );
  }

  selectOption(option: string): void {
    this.selectedOption = option;

    if (this.selectedOption === 'phone') {
      this.phoneFormGroup.enable();
      this.emailFormGroup.reset();
      this.emailFormGroup.disable();
    } else if (this.selectedOption === 'email') {
      this.emailFormGroup.enable();
      this.phoneFormGroup.controls['phone'].setValue('');
      this.phoneFormGroup.controls['phone'].updateValueAndValidity();
      this.telInput?.reset();
      this.phoneFormGroup.disable();
    }
  }

  onRSVPChange(rsvpType: RSVPOptionType) {
    this.selectedRSVPType = rsvpType;
  }

  rsvp(rsvpType?: RSVPOptionType) {
    if (!this.event || !this.form) {
      return;
    }

    if (rsvpType) {
      this.onRSVPChange(rsvpType);
    }

    this.isNoAccountRSVPVerify = false;

    if ((this.form.invalid || !this.selectedRSVPType) && !this.isAttendee) {
      this.form.markAllAsTouched();

      if (this.selectedOption === 'phone') {
        this.telInput?.markAsTouched();
      }

      return;
    }

    const values = this.form.value;
    const phoneValues = this.phoneFormGroup?.value;
    const emailValues = this.emailFormGroup?.value;

    const req: EventRSVPRequest = {
      rsvpType: this.selectedRSVPType,
      numAttendees: values.numAttendees,
      comment: values.comment,
    };
    if (this.event.eventTemplate?.rsvpSettings?.requireGroupRsvp) {
      req.groupName = values.groupName;
    }

    if (this.onWaitlist) {
      req.joinWaitlist = true;
    }

    if (this.event.eventTemplate?.attendeeQuestions) {
      const answers: RSVPQuestionAnswer[] = [];
      for (const question of this.event.eventTemplate.attendeeQuestions) {
        if (values[`question#${question.id}`]) {
          const answer: RSVPQuestionAnswer = {
            eventAttendeeQuestionId: question.id,
          };

          if (
            question.type === EventAttendeeQuestionType.DROPDOWN &&
            !!values[`question#${question.id}`]
          ) {
            answer.eventAttendeeQuestionOptionId =
              +values[`question#${question.id}`];
          } else if (question.type === EventAttendeeQuestionType.TEXT) {
            answer.openAnswer = values[`question#${question.id}`];
          } else if (question.type === EventAttendeeQuestionType.CHECKBOX) {
            answer.boolAnswer = !!values[`question#${question.id}`];
          } else if (question.type === EventAttendeeQuestionType.NUMBER) {
            answer.openAnswer = values[`question#${question.id}`].toString();
          }

          answers.push(answer);
        }
      }

      req.rsvpQuestionAnswers = answers;
    }

    const createOrFindUserReq: NewUserCreationRequest = {
      name: values.name,
    };

    if (!this.loggedUser) {
      if (this.selectedOption === 'email') {
        createOrFindUserReq.email = emailValues.email;
        createOrFindUserReq.password = emailValues.password;
      } else {
        createOrFindUserReq.phone = phoneValues.phone;
      }

      createOrFindUserReq.createAccount = true;
      createOrFindUserReq.eventId = this.event.id;
    }

    if (!this.loggedUser && this.selectedOption === 'phone') {
      this.inProgress = true;

      this.userService.createOrFindUser(createOrFindUserReq).subscribe({
        next: (userId: number) => {
          this.inProgress = false;
          this.otpUserId = userId;
          this.rsvpReq = req;
          this.userPhone = phoneValues.phone;
          this.step++;
        },
        error: () => {
          this.inProgress = false;
          this.notificationService.error(
            this.translateService.instant('APP.EVENT_RSVP.ERRORS.SIGN_UP'),
            this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
          );
        },
      });
    } else if (!this.loggedUser && this.selectedOption === 'email') {
      if (this.accountWithEmailExists) {
        this.rsvpReq = req;
        this.loginRequest = {
          email: emailValues.email,
          password: emailValues.password,
        };

        if (
          this.event.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData() &&
          this.rsvpReq.rsvpType === RSVPOptionType.YES
        ) {
          this.step++;
        } else {
          this.saveRSVP({
            req: this.rsvpReq,
            loginRequest: this.loginRequest,
          });
        }
      } else {
        this.inProgress = true;

        this.userService.createOrFindUser(createOrFindUserReq).subscribe({
          next: (userId: number) => {
            this.inProgress = false;
            this.otpUserId = userId;
            this.rsvpReq = req;
            this.loginRequest = {
              email: emailValues.email,
              password: emailValues.password,
            };
            this.step++;
          },
          error: () => {
            this.inProgress = false;
            this.notificationService.error(
              this.translateService.instant('APP.EVENT_RSVP.ERRORS.SIGN_UP'),
              this.translateService.instant(
                'APP.EVENT_RSVP.ERRORS.COULDNT_RSVP',
              ),
            );
          },
        });
      }
    } else {
      const previousRSVP = this.event.getUserAttendee(this.loggedUser?.id || 0);
      if (
        req.rsvpType !== previousRSVP?.rsvpType ||
        req.numAttendees !== previousRSVP?.getPlusOnes() ||
        req.groupName !== previousRSVP?.groupName
      ) {
        if (this.event?.isPreviewEvent) {
          this.inProgress = false;
          this.dialogRef.close({
            refresh: true,
            openEventAfterAttendOverlayDialog: true,
            rsvpType: req.rsvpType,
          });
          return;
        }

        if (
          this.event.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData() &&
          req.rsvpType === RSVPOptionType.YES &&
          !previousRSVP
        ) {
          this.rsvpReq = req;
          this.step += 2;
        } else {
          this.saveRSVP({
            req: req,
          });
        }
      } else {
        this.inProgress = false;
        this.dialogRef.close();
      }
    }
  }

  rsvpWithoutAccount() {
    if (!this.event || !this.form) {
      return;
    }

    if (
      (!this.loggedUser &&
        this.selectedOption === 'email' &&
        (this.emailFormGroup.controls['email'].invalid ||
          this.form.controls['name'].invalid)) ||
      !this.selectedRSVPType
    ) {
      this.form.controls['name'].markAsTouched();
      this.emailFormGroup.markAllAsTouched();
      return;
    }

    const values = this.form.value;
    const emailValues = this.emailFormGroup?.value;

    const req: EventRSVPRequest = {
      rsvpType: this.selectedRSVPType,
      numAttendees: values.numAttendees,
      comment: values.comment,
      email: emailValues.email,
    };

    if (this.event.eventTemplate?.rsvpSettings?.requireGroupRsvp) {
      req.groupName = values.groupName;
    }

    if (this.onWaitlist) {
      req.joinWaitlist = true;
    }

    const createOrFindUserReq: NewUserCreationRequest = {
      name: values.name,
      email: emailValues.email,
      createAccount: false,
      eventId: this.event.id,
    };

    this.inProgressWithoutAccount = true;

    this.userService.createOrFindUser(createOrFindUserReq).subscribe({
      next: (userId: number) => {
        this.isNoAccountRSVPVerify = true;
        this.inProgressWithoutAccount = false;
        this.otpUserId = userId;
        this.rsvpReq = req;
        this.step++;
      },
      error: () => {
        this.inProgressWithoutAccount = false;
        this.notificationService.error(
          this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
        );
      },
    });
  }

  onNoAccountVerifyCodeCompleted(code: string) {
    if (!this.event || !this.rsvpReq) {
      return;
    }

    const req: VerifyNoAccountRSVPRequest = {
      otp: code,
      email: this.rsvpReq?.email,
      eventRSVPRequest: this.rsvpReq,
    };

    this.eventService.verifyNoAccountRSVP(this.event.id, req).subscribe({
      next: () => {
        this.dialogRef.close({
          refresh: true,
          openEventAfterAttendOverlayDialog: true,
          rsvpType: this.rsvpReq!.rsvpType,
        });
      },
      error: (err) => {
        if (err && err.error.startsWith('could not verify user')) {
          this.isNoAccountRSVPVerifyWrongCode = true;
        } else {
          this.checkAndShowRSVPError(err);
        }
      },
    });
  }

  saveRSVP(res: any) {
    if (res && this.event) {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;
      if (this.loggedUser && this.loggedUser.id) {
        this.eventService
          .saveRSVP(this.event?.id, res.req)
          .pipe(
            finalize(() => {
              this.inProgress = false;
            }),
          )
          .subscribe({
            next: () => {
              this.dialogRef.close({
                refresh: true,
                openEventAfterAttendOverlayDialog: true,
                rsvpType: res.req.rsvpType,
                attendeeData: res.req,
              });
            },
            error: (err) => {
              this.checkAndShowRSVPError(err);
            },
          });
      } else if (res.loginRequest) {
        if (res.loginRequest.email) {
          this.authService.login(
            res.loginRequest,
            false,
            () => {
              if (this.event?.isPreviewEvent) {
                this.dialogRef.close({
                  refresh: true,
                  openEventAfterAttendOverlayDialog: true,
                  rsvpType: res.req.rsvpType,
                });
                return;
              }

              this.eventService
                .saveRSVP(this.event?.id, res?.req)
                .pipe(
                  finalize(() => {
                    this.inProgress = false;
                  }),
                )
                .subscribe({
                  next: () => {
                    this.dialogRef.close({
                      refresh: true,
                      openEventAfterAttendOverlayDialog: true,
                      rsvpType: res.req.rsvpType,
                      attendeeData: res.req,
                    });
                  },
                  error: (err) => {
                    this.checkAndShowRSVPError(err);
                  },
                });
            },
            (denied: any) => {
              this.inProgress = false;
              if (denied && denied.message) {
                if (denied.message === 'account_blocked') {
                  this.emailFormGroup.controls['password'].setErrors({
                    custom: this.translateService.instant(
                      'APP.AUTH.ERRORS.ACCOUNT_BLOCKED',
                    ),
                  });
                } else if (denied.message === 'unauthorized') {
                  this.emailFormGroup.controls['password'].setErrors({
                    custom: this.translateService.instant(
                      'APP.AUTH.ERRORS.WRONG_PASSWORD',
                    ),
                  });
                }
              } else {
                this.emailFormGroup.controls['password'].setErrors({
                  custom: this.translateService.instant(
                    'APP.AUTH.ERRORS.INVALID_EMAIL_OR_PASSWORD',
                  ),
                });
              }
            },
          );
        } else if (res.loginRequest.phone) {
          this.authService.loginWithOtp(
            res.loginRequest,
            false,
            () => {
              if (this.event?.isPreviewEvent) {
                this.dialogRef.close({
                  refresh: true,
                  openEventAfterAttendOverlayDialog: true,
                  rsvpType: res.req.rsvpType,
                });
                return;
              }

              this.eventService
                .saveRSVP(this.event?.id, res?.req)
                .pipe(
                  finalize(() => {
                    this.inProgress = false;
                  }),
                )
                .subscribe({
                  next: () => {
                    this.dialogRef.close({
                      refresh: true,
                      openEventAfterAttendOverlayDialog: true,
                      rsvpType: res.req.rsvpType,
                      attendeeData: res.req,
                    });
                  },
                  error: (err) => {
                    this.checkAndShowRSVPError(err);
                  },
                });
            },
            () => {
              this.inProgress = false;
              this.isWrongCode = true;
              this.notificationService.error(
                this.translateService.instant('APP.AUTH.ERRORS.INVALID_CODE'),
              );
            },
          );
        }
      }
    } else {
      this.inProgress = false;
    }
  }

  removeRSVP() {
    if (
      this.event &&
      this.event.id &&
      this.event.requireAttendeeApproval &&
      this.loggedUser &&
      this.loggedUser.id
    ) {
      const userAttendee = this.event.getUserAttendee(this.loggedUser.id);
      if (userAttendee) {
        this.eventService
          .deleteAttendee(this.event.id, userAttendee.id)
          .subscribe({
            next: () => {
              this.dialogRef.close({
                refresh: true,
                openEventAfterAttendOverlayDialog: false,
              });
            },
            error: (err) => {
              this.checkAndShowRSVPError(err);
            },
          });
      }
    }
  }

  onVerifyPhoneSuccess(res: any) {
    if (
      this.event?.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData() &&
      res.req.rsvpType === RSVPOptionType.YES
    ) {
      this.rsvpReq = res.req;
      this.loginRequest = res.loginRequest;
      this.step++;
    } else {
      this.saveRSVP(res);
    }
  }

  onVerifyEmailSuccess() {
    if (
      this.event?.eventTemplate?.rsvpSettings?.hasPlusOnesAdditionalData() &&
      this.rsvpReq?.rsvpType === RSVPOptionType.YES
    ) {
      this.step++;
    } else {
      this.saveRSVP({
        req: this.rsvpReq,
        loginRequest: this.loginRequest,
      });
    }
  }

  onAdditionalPlusOnesDataNext() {
    if (!this.event || !this.form || !this.rsvpReq) {
      return;
    }

    const value = this.form.value;
    if (value.plusOnes) {
      const groupMembers: RSVPGroupMemberData[] = [];
      for (const plusOne of value.plusOnes) {
        const groupMember: RSVPGroupMemberData = {};
        if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesFirstName) {
          groupMember.firstName = plusOne.firstName;
        }
        if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesLastName) {
          groupMember.lastName = plusOne.lastName;
        }
        if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesEmail) {
          groupMember.email = plusOne.email;
        }
        if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesPhone) {
          groupMember.phone = plusOne.phone;
        }

        if (this.event.eventTemplate?.attendeeQuestions) {
          const answers: RSVPQuestionAnswer[] = [];
          for (const question of this.event.eventTemplate.attendeeQuestions) {
            const answer: RSVPQuestionAnswer = {
              eventAttendeeQuestionId: question.id,
            };

            if (
              question.type === EventAttendeeQuestionType.DROPDOWN &&
              !!plusOne[`question#${question.id}`]
            ) {
              answer.eventAttendeeQuestionOptionId =
                +plusOne[`question#${question.id}`];
            } else if (question.type === EventAttendeeQuestionType.TEXT) {
              answer.openAnswer = plusOne[`question#${question.id}`];
            } else if (question.type === EventAttendeeQuestionType.CHECKBOX) {
              answer.boolAnswer = !!plusOne[`question#${question.id}`];
            }

            answers.push(answer);
          }

          groupMember.rsvpQuestionAnswers = answers;
        }

        groupMembers.push(groupMember);
      }

      this.rsvpReq.groupMembers = groupMembers;

      this.rsvpReq.numAttendees = groupMembers.length;
    }

    this.saveRSVP({
      req: this.rsvpReq,
      loginRequest: this.loginRequest,
    });
  }

  checkUserWithEmailExists(): void {
    if (this.emailFormGroup.controls['email'].invalid) {
      return;
    }

    const email = this.emailFormGroup.controls['email'].value;

    let params: Map<string, any> = new Map<string, any>();
    params.set('isSignUp', true);

    this.authService.userOrAccountWithEmailExists(email, params).subscribe({
      next: (exists: boolean) => {
        if (!exists) {
          this.accountWithEmailExists = false;
          this.emailFormGroup.controls['acceptedPrivacyPolicy'].addValidators(
            Validators.requiredTrue,
          );
          this.emailFormGroup.controls['acceptedTerms'].addValidators(
            Validators.requiredTrue,
          );
        } else {
          this.accountWithEmailExists = true;
          this.emailFormGroup.controls[
            'acceptedPrivacyPolicy'
          ].clearValidators();
          this.emailFormGroup.controls['acceptedTerms'].clearValidators();
        }
        this.emailFormGroup.controls[
          'acceptedPrivacyPolicy'
        ].updateValueAndValidity();
        this.emailFormGroup.controls['acceptedTerms'].updateValueAndValidity();
      },
    });
  }

  goToForgotPassword(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/', AppRoutes.Root.forgotPassword]),
    );
    window.open(url, '_blank');
  }

  private checkAndShowRSVPError(err: any) {
    if (err?.error === 'bad request - max capacity exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.MAX_CAPACITY'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - rsvp disabled') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.DISABLED'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - plus ones limit exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.PLUS_ONES_LIMIT'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    }
  }

  private setRsvpDialogColors() {
    document.documentElement.style.setProperty(
      '--rsvp-dialog-body-color',
      this.bodyColor,
    );
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get yesRSVPOption(): EventRSVPOption | undefined {
    return this.event?.getYesRSVPOption();
  }

  get isSportpageEvent(): boolean {
    return this.event?.type === EventType.SPORTPAGE;
  }

  protected readonly requestAnimationFrame = requestAnimationFrame;
  protected readonly RSVPLayout = RSVPLayout;
  protected readonly RSVPOptionType = RSVPOptionType;
  protected readonly EventType = EventType;
  protected readonly RSVPDialogStep = RSVPDialogStep;
  protected readonly EventRSVPConfirmationPageSectionType =
    EventRSVPConfirmationPageSectionType;
  protected readonly Helpers = Helpers;
}
