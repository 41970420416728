export enum TicketAction {
  ADD_TO_WALLET = 'ADD_TO_WALLET',
  SEND = 'SEND',
  SHARE = 'SHARE',
  DOWNLOAD = 'DOWNLOAD',
  REFUND = 'REFUND',
  CANCEL_REFUND_REQUEST = 'CANCEL_REFUND_REQUEST',
}

export namespace TicketAction {
  export function getAllTicketActions(): TicketAction[] {
    return [
      TicketAction.ADD_TO_WALLET,
      TicketAction.SEND,
      TicketAction.SHARE,
      TicketAction.DOWNLOAD,
      TicketAction.REFUND,
    ];
  }

  export function getAllRequestedRefundActions(): TicketAction[] {
    return [TicketAction.CANCEL_REFUND_REQUEST, TicketAction.DOWNLOAD];
  }

  export function getDisplayName(action?: TicketAction): string {
    switch (action) {
      case TicketAction.ADD_TO_WALLET:
        return 'APP.TICKET_ACTIONS.ADD_TO_WALLET';
      case TicketAction.SEND:
        return 'APP.TICKET_ACTIONS.SEND';
      case TicketAction.SHARE:
        return 'APP.TICKET_ACTIONS.SHARE';
      case TicketAction.DOWNLOAD:
        return 'APP.TICKET_ACTIONS.DOWNLOAD';
      case TicketAction.REFUND:
        return 'APP.TICKET_ACTIONS.REFUND';
      case TicketAction.CANCEL_REFUND_REQUEST:
        return 'APP.TICKET_ACTIONS.CANCEL_REFUND_REQUEST';
      default:
        return '';
    }
  }

  export function getIcon(action?: TicketAction): string {
    switch (action) {
      case TicketAction.ADD_TO_WALLET:
        return 'far fa-plus';
      case TicketAction.SEND:
        return 'far fa-paper-plane';
      case TicketAction.SHARE:
        return 'far fa-share';
      case TicketAction.DOWNLOAD:
        return 'far fa-download';
      case TicketAction.REFUND:
        return 'far fa-redo';
      case TicketAction.CANCEL_REFUND_REQUEST:
        return 'far fa-times';
      default:
        return '';
    }
  }
}
