import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-action',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './ticket-action.component.html',
  styleUrl: './ticket-action.component.scss',
})
export class TicketActionComponent {
  @Input() icon?: string;
  @Input() label?: string;
  @Output() onClick = new EventEmitter();

  click() {
    this.onClick.emit();
  }
}
