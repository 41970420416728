import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { EventTicketRequest } from '@models/events/dto/event-ticket.request';
import { SaveTicketPaymentOptionsRequest } from '@models/events/dto/save-ticket-payment-options.request';
import { SaveTicketPayoutSettingsRequest } from '@models/events/dto/save-ticket-payout-settings.request';
import { SaveTicketRefundPolicyRequest } from '@models/events/dto/save-ticket-refund-policy,request';
import { SaveTicketSellerRequest } from '@models/events/dto/save-ticket-seller.request';
import { EventTicket } from '@models/events/event-ticket.model';
import { TicketVoucherCodeResponse } from '@models/tickets/dto/ticket-voucher-code.response';
import { TicketSellerAccountSessionResponse } from '@models/tickets/dto/ticket_seller_account_session.response';
import { TicketVoucherCode } from '@models/tickets/ticket-voucher-code.model';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketService {
  constructor(private api: ApiService) {}

  getTickets(eventId: number): Observable<EventTicket[]> {
    return this.api
      .get(`${env.api}/tickets?eventId=${eventId}`)
      .pipe(map(this.mapEventTickets));
  }

  get(id: number): Observable<EventTicket> {
    return this.api
      .get(`${env.api}/tickets/${id}`)
      .pipe(map((it) => new EventTicket(it)));
  }

  create(object: EventTicketRequest): Observable<EventTicket> {
    return this.api
      .post(`${env.api}/tickets`, object)
      .pipe(map((it) => new EventTicket(it)));
  }

  copy(object: EventTicket): Observable<EventTicket> {
    return this.api
      .post(`${env.api}/tickets/${object.id}/copy`, object)
      .pipe(map((it) => new EventTicket(it)));
  }

  delete(ticketId: number): Observable<void> {
    return this.api.delete(`${env.api}/tickets/${ticketId}`);
  }

  createTicketSeller(request: SaveTicketSellerRequest) {
    return this.api.post(`${env.api}/tickets/ticket-sellers`, request);
  }

  createTicketPayoutSettings(request: SaveTicketPayoutSettingsRequest) {
    return this.api.post(`${env.api}/tickets/ticket-payout-settings`, request);
  }

  createTicketRefundPolicies(request: SaveTicketRefundPolicyRequest) {
    return this.api.post(`${env.api}/tickets/ticket-refund-policies`, request);
  }

  createTicketPaymentOptions(request: SaveTicketPaymentOptionsRequest) {
    return this.api.post(`${env.api}/tickets/ticket-payment-options`, request);
  }

  creatTicketSellerStripeAccountSession(
    eventId: number,
  ): Observable<TicketSellerAccountSessionResponse> {
    return this.api.post(`${env.api}/tickets/ticket-seller-account-session`, {
      eventId,
    });
  }

  getVoucherCodes(eventId: number): Observable<TicketVoucherCodeResponse[]> {
    return this.api
      .get(`${env.api}/tickets/voucher-codes?eventId=${eventId}`)
      .pipe(map(this.mapTicketVoucherCodes));
  }

  getVoucherCode(
    code: string,
    eventId: number,
  ): Observable<TicketVoucherCodeResponse> {
    return this.api.get(
      `${env.api}/tickets/voucher-codes/${code}?eventId=${eventId}`,
    );
  }

  createVoucherCode(request: TicketVoucherCode) {
    return this.api.post(`${env.api}/tickets/voucher-codes`, request);
  }

  getPublicVoucherCode(eventId: number): Observable<TicketVoucherCodeResponse> {
    return this.api.get(
      `${env.api}/tickets/public-voucher-code?eventId=${eventId}`,
    );
  }

  deleteVoucherCode(id: number) {
    return this.api.delete(`${env.api}/tickets/voucher-codes/${id}`);
  }

  private mapEventTickets(res: EventTicket[]) {
    return res?.map((it) => new EventTicket(it));
  }

  private mapTicketVoucherCodes(res: TicketVoucherCodeResponse[]) {
    return res?.map((it) => new TicketVoucherCodeResponse(it));
  }
}
