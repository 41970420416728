<div class="bought-tickets-container event-dialog-content" mat-dialog-content>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">
      {{ "APP.REQUEST_REFUND.REQUESTED_REFUND" | translate}}
    </div>

      <app-qr-data-card
        [header]=getHeaderText()
        [footer]=env.platform
        [loggedUser]="loggedUser"
        [isBackSide]="false"
        [allowFlip]='false'
        [logo]="env.logoAsset"
        [refund]='order?.refund || undefined'
        [brandColors]='false'
        (onAction)='contactHost()'
      ></app-qr-data-card>
    <div mat-dialog-actions class='ticket-actions'>
        @if(order?.refund?.status === EventAttendeeTicketOrderRefundStatus.SUCCEEDED){
          <app-ticket-action [label]=TicketAction.getDisplayName(TicketAction.DOWNLOAD)
                             [icon]=TicketAction.getIcon(TicketAction.DOWNLOAD)
                             (click)='executeAction(TicketAction.DOWNLOAD)'/>
        }

        @if(order?.refund?.status === EventAttendeeTicketOrderRefundStatus.PENDING){
          <app-ticket-action [label]=TicketAction.getDisplayName(TicketAction.CANCEL_REFUND_REQUEST)
                             [icon]=TicketAction.getIcon(TicketAction.CANCEL_REFUND_REQUEST)
                             (click)='executeAction(TicketAction.CANCEL_REFUND_REQUEST)'/>
        }
    </div>
  </div>
</div>
