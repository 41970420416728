<div class='generic-get-tickets-wrapper'>
  @if(!showSuccessPage){
    @if(isDesktop){
      <div class='item payment'>
        <ng-container *ngTemplateOutlet="payment"></ng-container>
      </div>
      <div class='item'>
        <div class='ticket-selection-content smaller-ticket-view'>
          <!--Event info-->
          <ng-container *ngTemplateOutlet="eventInfo"></ng-container>

          <!--Get tickets accordion-->
          <mat-accordion class="wrapper-expansion partly-clickable" multi>
            <mat-expansion-panel  [expanded]="panelOpenState()"
                                  (opened)="panelOpenState.set(true)"
                                  (closed)="panelOpenState.set(false)">
              <mat-expansion-panel-header>
                <mat-panel-title>{{ "APP.EVENT.GET_TICKETS" | translate  }}</mat-panel-title>
                <mat-panel-description>
            <span class='panel-description' (click)='togglePanelState($event)'>
              @if(panelOpenState()){
                {{ 'APP.DONE' | translate }}
              }@else{
                {{ 'APP.EDIT' | translate }}
              }
            </span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="ticket-wrapper">
                <ng-container *ngTemplateOutlet="ticketsSelection"></ng-container>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <!--Apply voucher and Total price section-->
          <ng-container *ngTemplateOutlet="orderOverview"></ng-container>
        </div>
      </div>
    }@else{
      <div class='item ticket-selection-content smaller-ticket-view'>
        <!--Event info-->
        <ng-container *ngTemplateOutlet="eventInfo"></ng-container>

        <!--Get tickets accordion-->
        <mat-accordion multi>
          <mat-expansion-panel class="wrapper-expansion"
                               [expanded]="ticketsOpenState()"
                               (opened)="ticketsOpenState.set(true)"
                               (closed)="ticketsOpenState.set(false)">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ "APP.TICKET_ACTIONS.TICKETS" | translate}}</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="ticket-wrapper pt-20">
              <ng-container *ngTemplateOutlet="ticketsSelection"></ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <!--Apply voucher and Total price section-->
        <ng-container *ngTemplateOutlet="orderOverview"></ng-container>

        <!--Payment section-->
        <ng-container *ngTemplateOutlet="payment"></ng-container>
      </div>
    }
  }@else{
    <app-ticket-payment-success
      [ticketOrder]="ticketOrder"
      [mainImage]="mainImage"
      [event]="event"
      (onClose)='close()'
    />
  }
</div>

<ng-template #eventInfo>
  <div class="event-info-wrapper">
    <img [src]="mainImage" class="key-visual-img logo-image" alt="Key Visual" />
    <app-event-info
      [event]="event"
      [showDescription]="false"
      [dateTimeNewRow]="true">
    </app-event-info>
  </div>
</ng-template>

<ng-template #orderOverview>
  <div class='selection-footer'>
    @if(!panelOpenState() || (!isDesktop && !ticketsOpenState())){
      @if (createOrderTicketsMap()) {
        @for (ticket of createOrderTicketsMap() | keyvalue; track $index) {
          <div class="order-item-wrapper">
            <div class="item">
              <div>
                {{ ticket.value[0].quantity }} x
                {{ ticket.key.name }}
              </div>
             <!-- <div class="date">{{ attendeeTicket.ticket.eventTicket?.day | date: 'EEE. dd. MMMM' }}</div>-->
            </div>

            <div class="price">
              @if(checkIfDiscountApplied(ticket.key)){
                {{ calculateDiscount(ticket.key) }}
              }
              @else{
                {{
                  NumberUtils.roundToUpTo2Decimals(
                    (ticket.value[0].quantity * ticket.key.price) / 100.0
                  )
                }}
                &#8364;
              }
            </div>
          </div>
        }
        <div class="divider"></div>
      }
    }

    @if (form) {
      <mat-accordion class="wrapper-expansion partly-clickable" multi>
        <mat-expansion-panel  [expanded]="applyVoucherOpenState()"  (opened)="applyVoucherOpenState.set(true)" (closed)="applyVoucherOpenState.set(false)">
          <mat-expansion-panel-header>
            <mat-panel-title><span class='panel-title'>{{ "APP.VOUCHERS.APPLY_VOUCHER" | translate }}</span></mat-panel-title>
          </mat-expansion-panel-header>

          <form [formGroup]="form" class="voucher-form">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label class="placeholder">{{
                  "APP.VOUCHERS.VOUCHER_CODE" | translate
                }}</mat-label>
              <input
                formControlName="voucherCode"
                type="text"
                matInput
              />
              @if (voucherApplied) {
                <div matSuffix class="suffix-actions">
                  <div class="success-code">
                    <i class="fas fa-check-circle"></i>
                  </div>
                  <div
                    class="remove-button"
                    [matTooltip]="'APP.REMOVE' | translate"
                    (click)="removeCode()"
                  >
                    <i class="far fa-trash"></i>
                  </div>
                </div>
              } @else {
                <div matSuffix class="suffix-actions">
                  @if (unrecognizedCode) {
                    <div class="danger-code">
                      <i class="fas fa-minus-circle"></i>
                    </div>
                  } @else {
                    <div
                      matSuffix
                      class="apply-button"
                      [matTooltip]="'APP.APPLY' | translate"
                      (click)="applyCode()"
                    >
                      {{ "APP.APPLY" | translate }}
                    </div>
                  }
                </div>
              }
              @if (unrecognizedCode) {
                <mat-hint class="code-hint danger text">{{
                    "APP.VOUCHERS.ERRORS.UNRECOGNIZED_CODE" | translate
                  }}</mat-hint>
              }
              @if (voucherApplied) {
                <mat-hint class="code-hint text">
                  {{ voucherApplied.codeName }}
                  {{ "APP.VOUCHERS.APPLIED" | translate }}
                </mat-hint>
              }
            </mat-form-field>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    }

    <div class="total">
      {{ "APP.VOUCHERS.TOTAL" | translate }}:
      <span class="price"
      >{{
          NumberUtils.roundToUpTo2Decimals(fullPrice / 100.0)
        }}
        &#8364;</span
      >
    </div>
  </div>
</ng-template>

<ng-template #ticketsSelection>
  @for (ticket of filteredTickets; track ticket.id) {
    <app-event-ticket-selection-view
      [isSoldOut]="checkIfSoldOut(ticket)"
      [ticket]="ticket"
      [orderTickets]="orderTickets"
      [ticketsWithAppliedDiscount]="ticketsWithAppliedDiscount"
      [appliedDiscount]="checkIfDiscountApplied(ticket)"
      [appliedVoucher]="voucherApplied"
      [ticketQuantity]="ticket.id ? getTicketQuantity(ticket.id) : 0"
      [currentTicketQuantity]="currentTicketQuantity"
      (addTicket)="addTicket($event)"
      (removeTicket)="removeTicket($event)"
    />
  }
</ng-template>

<ng-template #payment>
  <app-event-payment
    [event]="event"
    [ticketOrder]="ticketOrder"
    [mainImage]="mainImage"
    [voucher]='voucherApplied'
    [isGeneric]='true'
    [canPay]='!checkIfNoTickets()'
    [updatedSessionRequest]='updatedSessionRequest'
    (nextStep)="onPaymentSuccess()"
  />
</ng-template>
