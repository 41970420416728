import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { Event } from '@models/events/event.model';
import { OrderOverviewComponent } from '@modules/events/components/event-payment/components/order-overview/order-overview.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { FooterComponent } from '../../../../components/footer/footer.component';

@Component({
  selector: 'app-ticket-payment-success',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    OrderOverviewComponent,
    FooterComponent,
  ],
  providers: [EventService, NotificationService],
  templateUrl: './ticket-payment-success.component.html',
  styleUrl: './ticket-payment-success.component.scss',
  schemas: [],
})
export class TicketPaymentSuccessComponent {
  private _ticketOrder?: EventAttendeeTicketOrder;
  isDesktop?: boolean;

  @Input() set ticketOrder(ticketOrder: EventAttendeeTicketOrder | undefined) {
    if (ticketOrder) {
      this._ticketOrder = ticketOrder;
    }
  }

  get ticketOrder(): EventAttendeeTicketOrder | undefined {
    return this._ticketOrder;
  }

  @Input() mainImage?: string;
  @Input() event?: Event;
  @Output() onClose = new EventEmitter();

  constructor(
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
  ) {
    this.screenWidthService.isDesktop().subscribe({
      next: (isDesktop: boolean) => {
        this.isDesktop = isDesktop;
      },
    });
  }

  downloadTicketPdf() {
    if (!this.ticketOrder) {
      return;
    }

    this.eventService.downloadTicketOrderPdf(this.ticketOrder.uuid).subscribe({
      error: () => {
        this.notificationService.error(
          this.translateService.instant('APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD'),
        );
      },
    });
  }

  addToWallet() {
    if (!this.ticketOrder) {
      return;
    }

    this.eventService
      .getEventTicketOrderWalletPassBundle(this.ticketOrder.uuid)
      .subscribe({
        next: (passPageLink: string) => {
          window.open(passPageLink, '_blank');
        },
        error: () => {
          this.notificationService.error(
            this.translateService.instant(
              'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
            ),
          );
        },
      });
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  close() {
    this.onClose.emit();
  }
}
