<div class="bought-tickets-container event-dialog-content" mat-dialog-content>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">
      {{ "APP.TICKET_ACTIONS.TICKETS" | translate}}
    </div>
    <swiper-container
      appSwiper
      [config]="swiperConfig"
      #swiperRef
      #swiperContainer
      class="events-swiper event-card-list"
    >
      @for (ticket of tickets; track ticket.id) {
        <swiper-slide>
          <div class='slide-item'>
            <app-qr-data-card
              [header]=getHeaderText()
              [index]=$index
              [footer]=env.platform
              [qrData]="selectedTicket?.uuid || ''"
              [logo]="env.logoAsset"
              [qrLogo]="
          env.platformShort === 'partypage'
            ? './assets/pp_logo_qr.png'
            : './assets/ep_logo_qr.png'
        "
              [data]="ticket?.ticketNumber"
              [loggedUser]="loggedUser"
              [isBackSide]="false"
              [allowFlip]='false'
              [brandColors]='false'
            ></app-qr-data-card>
          </div>

        </swiper-slide>
      }
    </swiper-container>
    <div mat-dialog-actions class='ticket-actions'>
      <swiper-container
        appSwiper
        #swiperElement
        [config]="swiperActionsConfig"
        #swiperRef
        class="events-swiper ticket-actions-swiper"
      >
        @for (ticketAction of TicketAction.getAllTicketActions(); track $index) {
          @if(!(ticketAction === TicketAction.REFUND && !event?.ticketRefundPolicy?.refundAllowed)){
            <swiper-slide>
              <app-ticket-action [label]=TicketAction.getDisplayName(ticketAction)
                                 [icon]=TicketAction.getIcon(ticketAction)
                                 (click)='executeAction(ticketAction)'/>
            </swiper-slide>
          }
        }
      </swiper-container>
    </div>
</div>
</div>
