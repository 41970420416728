import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Event } from '@models/events/event.model';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { EventService } from '@services/events/event.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-host',
  standalone: true,
  imports: [CommonModule, FormsModules, TranslateModule, MatDialogContent],
  providers: [EventService],
  templateUrl: './add-host.dialog.html',
  styleUrl: './add-host.dialog.scss',
})
export class AddHostDialog {
  event?: Event;

  form?: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddHostDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private eventService: EventService,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.event = data.event;

      if (this.event) {
        this.form = this.fb.group({
          email: ['', [Validators.required, Validators.email]],
        });
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  invite() {
    if (!this.form || !this.event) {
      return;
    }
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const value = this.form.value;

    this.eventService.addHost(this.event.id, { email: value.email }).subscribe({
      next: (host) => {
        if (host.user && (!host.user.email || host.user.email === '')) {
          host.user.email = value.email;
        }
        this.dialogRef.close(host);
      },
      error: (err) => {
        if (err?.error === 'could not load user: record not found') {
          this.form?.controls['email'].setErrors({
            custom: this.translateService.instant(
              'APP.EVENT_EDIT.ERRORS.USER_NOT_FOUND',
            ),
          });
        } else if (err?.error === 'duplicate email') {
          this.form?.controls['email'].setErrors({
            custom: this.translateService.instant(
              'APP.EVENT_EDIT.ERRORS.HOST_ALREADY_EXISTS_OR_INVITED',
            ),
          });
        } else {
          this.form?.controls['email'].setErrors({
            custom: this.translateService.instant(
              'APP.EVENT_EDIT.ERRORS.ADD_HOST',
            ),
          });
        }
      },
    });
  }
}
