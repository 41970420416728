export enum EventAttendeeTicketOrderRefundStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED_INITIATED = 'APPROVED_INITIATED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  SUCCEEDED = 'SUCCEEDED',
}

export namespace EventAttendeeTicketOrderRefundStatus {
  export function getAllEventAttendeeTicketOrderRefundStatuses(): EventAttendeeTicketOrderRefundStatus[] {
    return [
      EventAttendeeTicketOrderRefundStatus.PENDING,
      EventAttendeeTicketOrderRefundStatus.REJECTED,
      EventAttendeeTicketOrderRefundStatus.APPROVED_INITIATED,
      EventAttendeeTicketOrderRefundStatus.FAILED,
      EventAttendeeTicketOrderRefundStatus.CANCELED,
      EventAttendeeTicketOrderRefundStatus.SUCCEEDED,
    ];
  }

  export function getDisplayName(
    status?: EventAttendeeTicketOrderRefundStatus,
  ): string {
    switch (status) {
      case EventAttendeeTicketOrderRefundStatus.PENDING:
        return 'APP.REQUEST_REFUND.ORDER_REFUND_STATUSES.PENDING';
      case EventAttendeeTicketOrderRefundStatus.REJECTED:
        return 'APP.REQUEST_REFUND.ORDER_REFUND_STATUSES.REJECTED';
      case EventAttendeeTicketOrderRefundStatus.APPROVED_INITIATED:
        return 'APP.REQUEST_REFUND.ORDER_REFUND_STATUSES.APPROVED_INITIATED';
      case EventAttendeeTicketOrderRefundStatus.FAILED:
        return 'APP.REQUEST_REFUND.ORDER_REFUND_STATUSES.FAILED';
      case EventAttendeeTicketOrderRefundStatus.CANCELED:
        return 'APP.REQUEST_REFUND.ORDER_REFUND_STATUSES.CANCELED';
      case EventAttendeeTicketOrderRefundStatus.SUCCEEDED:
        return 'APP.REQUEST_REFUND.ORDER_REFUND_STATUSES.SUCCEEDED';
      default:
        return '';
    }
  }

  export function getStatusName(
    status?: EventAttendeeTicketOrderRefundStatus,
  ): string {
    switch (status) {
      case EventAttendeeTicketOrderRefundStatus.PENDING:
        return 'APP.REQUEST_REFUND.REFUND_REQUESTED';
      case EventAttendeeTicketOrderRefundStatus.REJECTED:
        return 'APP.REQUEST_REFUND.REFUND_REJECTED';
      case EventAttendeeTicketOrderRefundStatus.SUCCEEDED:
        return 'APP.REQUEST_REFUND.REFUND_APPROVED';
      default:
        return '';
    }
  }

  export function getStatusDescription(
    status?: EventAttendeeTicketOrderRefundStatus,
  ): string {
    switch (status) {
      case EventAttendeeTicketOrderRefundStatus.PENDING:
        return 'APP.REQUEST_REFUND.REFUND_REQUESTED_MESSAGE';
      case EventAttendeeTicketOrderRefundStatus.REJECTED:
        return 'APP.REQUEST_REFUND.REFUND_REJECTED_MESSAGE';
      case EventAttendeeTicketOrderRefundStatus.SUCCEEDED:
        return 'APP.REQUEST_REFUND.REFUND_APPROVED_MESSAGE';
      default:
        return '';
    }
  }
}
