@if (event && visual && font) {
  <div class="page-container">
    @if (visual.patternUrl) {
      <img class="pattern" [src]="visual.patternUrl" />
    }
    <div class="event-container" [class.host-view]="isHostView">
      <div class="party-action-container">
        @if (!eventActionsSticky) {
          @if (event.mainPictureUrl || visual.keyVisual1) {
            <div class="main-picture">
              @if (event.isMainPictureVideo()) {
                <video
                  class="key-visual-1-video"
                  [muted]="'muted'"
                  autoplay
                  playsinline
                  loop
                >
                  <source [src]="event.mainPictureUrl" type="video/mp4" />
                </video>
              } @else {
                <img
                  class="key-visual-1"
                  [src]="event.mainPictureUrl || visual.keyVisual1?.url"
                />
              }
              @if (
                event.hasCampaignLogo() &&
                event.eventCampaign?.campaign?.logoUrl
              ) {
                <div>
                  <img
                    class="campaign-logo"
                    [src]="event.eventCampaign?.campaign?.logoUrl"
                  />
                </div>
              }
              @if (isCampaign && campaignData) {
                <app-campaign-ranking
                  [campaignData]="campaignData"
                  [event]="event"
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                />
              }
            </div>
          }
          @if (!isVotingView && !isCohostInvitation) {
            @if(event.hasApp(EventWidget.SELL_TICKETS)){
              @if(isHostView || event.hasPublishedSellTickets()){
              <div class='tickets-section'>
                @if(isHostView && !event.hasPublishedSellTickets()){
                  <div class='publish-sell-tickets-host-view' (click)='onPublishSellTickets()'>
                    <span>{{"APP.TICKETING_SET_UP.PUBLISH_SELL_TICKETS" | translate}}</span>
                    <i class="far fa-chevron-right"></i>
                  </div>
                }
                @if(order){
                  @if(order.refund){
                    <div class='buy-tickets-wrapper one-row'>
                      <div class='header'>
                        <i class="far fa-redo"></i>
                        <div>{{ "APP.REQUEST_REFUND.YOU_REQUESTED_REFUND" | translate }}</div>
                      </div>
                      <button class="btn btn-primary-party-page" (click)="openRequestedRefundOverviewDialog()">
                        <i class="fas fa-chevron-right"></i>
                      </button>
                    </div>
                  }
                  <div class='buy-tickets-wrapper'>
                    <div class='header'>
                      <i class="far fa-ticket"></i>
                      <div>{{ "APP.REQUEST_REFUND.YOU_BOUGHT_TICKET" | translate }}</div>
                    </div>
                    <button class="btn btn-primary-party-page" (click)="openGetBoughtTicketsDialog()">
                      {{ "APP.REQUEST_REFUND.OPEN_TICKET" | translate }}
                    </button>
                  </div>
                }@else{
                  <div class='buy-tickets-wrapper'>
                    <div class='header'>
                      <i class="far fa-shopping-cart"></i>
                      <div>{{ "APP.EVENT.BUY_TICKETS_FOR_THE_EVENT" | translate }}</div>
                    </div>
                    <button class="btn btn-primary-party-page" (click)="openGetTicketsDialog()">
                      {{ "APP.EVENT.GET_TICKETS_FROM" | translate }} {{ event.getLowestTicketPriceDisplay()}}
                    </button>
                  </div>
                }
              </div>
              }

            }@else{
              @if (isAttendeeView) {
                @if (event.hasOneButtonSignUp() || event.hasTwoButtonSignUp()) {
                  <div class="event-actions attendee-view">
                    <div class="share-button small" (click)="addToCalendar()">
                      <i class="far fa-calendar-day"></i>
                    </div>
                    <app-event-rsvp
                      [loggedUserId]="loggedUser?.id"
                      [isLoggedUserHost]="
                      loggedUser &&
                      loggedUser.id &&
                      (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                    "
                      [showGuestCount]="event.showGuestCount"
                      [isAttendeeView]="isAttendeeView"
                    ></app-event-rsvp>
                    <div class="share-button small" (click)="shareEvent()">
                      <i class="far fa-share"></i>
                    </div>
                    <!--     <app-share-buttons
                           [event]="event"
                           [isEventPage]="true"
                         ></app-share-buttons>-->
                  </div>
                } @else {
                  <div class="event-actions attendee-view">
                    <div class="share-button" (click)="addToCalendar()">
                      <i class="far fa-calendar-day"></i>
                    </div>
                    <app-event-rsvp
                      [loggedUserId]="loggedUser?.id"
                      [isLoggedUserHost]="
                      loggedUser &&
                      loggedUser.id &&
                      (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                    "
                      [showGuestCount]="event.showGuestCount"
                      [isAttendeeView]="isAttendeeView"
                    ></app-event-rsvp>
                    <div class="share-button" (click)="shareEvent()">
                      <i class="far fa-share"></i>
                    </div>
                  </div>
                }
              } @else if (!event.maxCapacityReached()) {
                @if (event.requireAttendeeApproval) {
                  @if (userOnPendingList) {
                    <button class="btn transparent-button disabled-icon-button">
                      <i class="far fa-clock icon"></i>
                      {{ "APP.WAIT_LIST.PENDING_APPROVAL" | translate }}
                    </button>
                  } @else if (event.canRsvp()) {
                    <button
                      class="btn transparent-button"
                      (click)="getOnPendingList()"
                    >
                      {{ "APP.WAIT_LIST.GET_ON_THE_LIST" | translate }}
                    </button>
                  }
                } @else if (
                  event.canRsvp() || isUserHost || loggedUser?.isAdmin()
                  ) {
                  <div
                    class="event-actions"
                    [class.one-button-actions]="event.hasOneButtonSignUp()"
                  >
                    <app-event-rsvp
                      [loggedUserId]="loggedUser?.id"
                      [isLoggedUserHost]="
                      loggedUser &&
                      loggedUser.id &&
                      (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                    "
                      [showGuestCount]="event.showGuestCount"
                    ></app-event-rsvp>
                    <app-share-buttons
                      [event]="event"
                      [isEventPage]="true"
                    ></app-share-buttons>
                  </div>
                }
              } @else if (event.maxCapacityReached() && event.waitlistEnabled) {
                @if (userOnWaitList) {
                  <button class="btn transparent-button disabled-icon-button">
                    <i class="far fa-clock icon"></i>
                    {{ "APP.WAIT_LIST.JOINED_WAIT_LIST" | translate }}
                  </button>
                } @else if (event.canRsvp()) {
                  <button class="btn transparent-button" (click)="joinWaitlist()">
                    {{ "APP.WAIT_LIST.JOIN_WAIT_LIST" | translate }}
                  </button>
                }
              }
            }
          }
        }
      </div>

      <div class="party-info-container">
        @if (
          eventActionsSticky && (event.mainPictureUrl || visual.keyVisual1)
        ) {
          <div class="main-picture-cont">
            <div class="main-picture">
              @if (event.isMainPictureVideo()) {
                <video
                  class="key-visual-1-video"
                  [muted]="'muted'"
                  autoplay
                  playsinline
                  loop
                >
                  <source [src]="event.mainPictureUrl" type="video/mp4" />
                </video>
              } @else {
                <img
                  class="key-visual-1"
                  [src]="event.mainPictureUrl || visual.keyVisual1?.url"
                />
              }
              @if (
                event.hasCampaignLogo() &&
                event.eventCampaign?.campaign?.logoUrl
              ) {
                <div>
                  <img
                    class="campaign-logo"
                    [src]="event.eventCampaign?.campaign?.logoUrl"
                  />
                </div>
              }
              @if (isCampaign && campaignData) {
                <app-campaign-ranking
                  [campaignData]="campaignData"
                  [event]="event"
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                />
              }
            </div>
          </div>
        }
        @if (event.subHeader && event.subHeader !== "") {
          <div
            class="invitation-text body-font"
            [style.color]="visual.backgroundColor ? visual.backgroundColor : ''"
          >
            {{ event.subHeader }}
          </div>
        }

        <div class="event-content">
          <app-event-info [event]="event" />
        </div>

        <div class="hosts-section">
          <div class="hosts-title body-font">
            {{ "APP.EVENT.HOSTED_BY" | translate }}
          </div>
          <app-event-hosts
            [event]="event"
            [isVotingView]="isVotingView"
          ></app-event-hosts>
        </div>

        @if (eventActionsSticky && !isVotingView && !isCohostInvitation) {
          @if(event.hasApp(EventWidget.SELL_TICKETS)){
            @if(isHostView || event.hasPublishedSellTickets()){
              <div class='event-actions attendee-view'>
              <div class='tickets-section'>
                @if(isHostView && !event.hasPublishedSellTickets()){
                  <div class='publish-sell-tickets-host-view' (click)='onPublishSellTickets()'>
                    <span>{{"APP.TICKETING_SET_UP.PUBLISH_SELL_TICKETS" | translate}}</span>
                    <i class="far fa-chevron-right"></i>
                  </div>
                }
                @if(order){
                  @if(order.refund){
                    <div class='buy-tickets-wrapper one-row'>
                      <div class='header'>
                        <i class="far fa-redo"></i>
                        <div>{{ "APP.REQUEST_REFUND.YOU_REQUESTED_REFUND" | translate }}</div>
                      </div>
                      <button class="btn btn-primary-party-page" (click)="openRequestedRefundOverviewDialog()">
                        <i class="fas fa-chevron-right"></i>
                      </button>
                    </div>
                  }
                  <div class='buy-tickets-wrapper'>
                    <div class='header'>
                      <i class="far fa-ticket"></i>
                      <div>{{ "APP.REQUEST_REFUND.YOU_BOUGHT_TICKET" | translate }}</div>
                    </div>
                    <button class="btn btn-primary-party-page" (click)="openGetBoughtTicketsDialog()">
                      {{ "APP.REQUEST_REFUND.OPEN_TICKET" | translate }}
                    </button>
                  </div>
                }@else{
                  <div class='buy-tickets-wrapper'>
                    <div class='header'>
                      <i class="far fa-shopping-cart"></i>
                      <div>{{ "APP.EVENT.BUY_TICKETS_FOR_THE_EVENT" | translate }}</div>
                    </div>
                    <button class="btn btn-primary-party-page" (click)="openGetTicketsDialog()">
                      {{ "APP.EVENT.GET_TICKETS_FROM" | translate }} {{ event.getLowestTicketPriceDisplay()}}
                    </button>
                  </div>
                }

              </div>
            </div>
            }
          }@else{
            @if (isAttendeeView) {
              @if (event.hasOneButtonSignUp() || event.hasTwoButtonSignUp()) {
                <div class="event-actions attendee-view">
                  <div class="share-button small" (click)="addToCalendar()">
                    <i class="far fa-calendar-day"></i>
                  </div>
                  <app-event-rsvp
                    [loggedUserId]="loggedUser?.id"
                    [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                    [showGuestCount]="event.showGuestCount"
                    [isAttendeeView]="isAttendeeView"
                  ></app-event-rsvp>
                  <div class="share-button small" (click)="shareEvent()">
                    <i class="far fa-share"></i>
                  </div>
                  <!--      <app-share-buttons
                          [event]="event"
                          [isEventPage]="true"
                        ></app-share-buttons>-->
                </div>
              } @else {
                <div class="event-actions attendee-view">
                  <div class="share-button" (click)="addToCalendar()">
                    <i class="far fa-calendar-day"></i>
                  </div>
                  <app-event-rsvp
                    [loggedUserId]="loggedUser?.id"
                    [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                    [showGuestCount]="event.showGuestCount"
                    [isAttendeeView]="isAttendeeView"
                  ></app-event-rsvp>
                  <div class="share-button" (click)="shareEvent()">
                    <i class="far fa-share"></i>
                  </div>
                </div>
              }
            } @else if (!event.maxCapacityReached()) {
              @if (event.requireAttendeeApproval) {
                @if (userOnPendingList) {
                  <button class="btn transparent-button disabled-icon-button">
                    <i class="far fa-clock icon"></i>
                    {{ "APP.WAIT_LIST.PENDING_APPROVAL" | translate }}
                  </button>
                } @else if (event.canRsvp()) {
                  <button
                    class="btn transparent-button"
                    (click)="getOnPendingList()"
                  >
                    {{ "APP.WAIT_LIST.GET_ON_THE_LIST" | translate }}
                  </button>
                }
              } @else if (event.canRsvp() || isUserHost) {
                <div class="event-actions">
                  <app-event-rsvp
                    [loggedUserId]="loggedUser?.id"
                    [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                    [showGuestCount]="event.showGuestCount"
                  ></app-event-rsvp>
                </div>
                <app-share-buttons
                  [event]="event"
                  [isEventPage]="true"
                  class="share-buttons-standalone"
                ></app-share-buttons>
              }
            } @else if (event.maxCapacityReached() && event.waitlistEnabled) {
              @if (userOnWaitList) {
                <div class="event-actions">
                  <button class="btn transparent-button disabled-icon-button">
                    <i class="far fa-clock icon"></i>
                    {{ "APP.WAIT_LIST.JOINED_WAIT_LIST" | translate }}
                  </button>
                </div>
              } @else if (event.canRsvp()) {
                <div class="event-actions">
                  <button class="btn transparent-button" (click)="joinWaitlist()">
                    {{ "APP.WAIT_LIST.JOIN_WAIT_LIST" | translate }}
                  </button>
                </div>
              }
            }
          }

        }

        @if (!isServer && widgets && widgets.length > 0 && !isVotingView) {
          <mat-tab-group mat-stretch-tabs="false" class="event-widget-tabs">
            @if (widgets.includes(EventWidget.COMMENTS)) {
              <mat-tab label="{{ 'APP.COMMENTS.TITLE' | translate }}">
                <app-event-comments
                  [loggedUser]="loggedUser"
                ></app-event-comments>
              </mat-tab>
            }
          </mat-tab-group>
        }
      </div>
    </div>

    <app-footer
      class="after-footer"
      [showLogo]="true"
      [backgroundColor]="
        visual.footerColor !== ''
          ? visual.footerColor
          : 'rgba(255, 255, 255, 0.7)'
      "
    ></app-footer>
  </div>

  <div
    class="background-overlay"
    [class.host-view]="isHostView"
    [style.backgroundColor]="
      visual.backgroundColor ? visual.backgroundColor : ''
    "
    [style.background-image]="
      visualBackgroundUrl && visual.isBackgroundImage()
        ? 'url(' + visualBackgroundUrl + ')'
        : ''
    "
  >
    @if (visualBackgroundUrl && visual.isBackgroundVideo()) {
      <div class="background-video-cont">
        <video
          #backgroundVideo
          class="background-video"
          [muted]="'muted'"
          playsinline
          loop
          preload="metadata"
        >
          @if (visualBackgroundHevcUrl !== "") {
            <source
              [src]="visualBackgroundHevcUrl + '#t=0.001'"
              type="video/mp4;codecs=hvc1"
            />
          }
          <source
            [src]="visualBackgroundUrl"
            [type]="visual.background?.mimeType || 'video/mp4'"
          />
        </video>
      </div>
    }
  </div>

  @if (visualEffectUrl) {
    <div
      class="effect-overlay"
      [style.background-image]="
        visualEffectUrl && visual.isEffectImage()
          ? 'url(' + visualEffectUrl + ')'
          : ''
      "
      [class.host-view]="isHostView"
      [class.one-time-effect]="!visual.effect?.repeat"
    >
      @if (!visual.isEffectImage()) {
        <video
          #effectVideo
          class="effect-video"
          [muted]="'muted'"
          playsinline
          [loop]="visual.effect?.repeat"
          preload="metadata"
        >
          @if (visualEffectHevcUrl !== "") {
            <source
              [src]="visualEffectHevcUrl + '#t=0.001'"
              type="video/mp4;codecs=hvc1"
            />
          }
          <source
            [src]="visualEffectUrl"
            [type]="visual.effect?.mimeType || 'video/mp4'"
          />
        </video>
      }
    </div>
  }
}
