import { ClassData } from '@models/class-data';
import { TicketVoucherCodeTicket } from '@models/tickets/ticket-voucher-code-ticket.model';
import { TicketVoucherCodeType } from '@models/tickets/ticket-voucher-code-type.enum';
import { NumberUtils } from '@utils/number-utils';

export class TicketVoucherCodeResponse {
  id?: number;
  createdAt?: Date;
  eventId?: number;
  type?: TicketVoucherCodeType;
  codeName?: string;
  limit?: number;
  discountFixedPrice?: number;
  discountPercentage?: number;
  startDate?: Date;
  endDate?: Date;
  tickets?: TicketVoucherCodeTicket[];
  numUses?: number;

  constructor(json: ClassData<TicketVoucherCodeResponse>) {
    this.id = json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.eventId = json.eventId;
    this.type = json.type;
    this.codeName = json.codeName;
    this.limit = json.limit;
    this.discountFixedPrice = json.discountFixedPrice;
    this.discountPercentage = json.discountPercentage;
    this.startDate = json.startDate;
    this.endDate = json.endDate;
    if (json.tickets) {
      this.tickets = json.tickets;
    }
    if (json.numUses !== undefined) {
      this.numUses = +json.numUses;
    }
  }

  getFixedPriceFormatted(): string | undefined {
    if (this.discountFixedPrice === undefined) {
      return undefined;
    }
    return (this.discountFixedPrice / 100.0).toFixed(2);
  }

  getDisplayFixedPrice(): string {
    if (!this.discountFixedPrice) {
      return '';
    }

    return `${NumberUtils.roundToUpTo2Decimals(
      this.discountFixedPrice / 100.0,
    )} €`;
  }
}
