import { ClassData } from '@models/class-data';
import { EventTicket } from '@models/events/event-ticket.model';
import { TicketLimitType } from '@models/tickets/ticket-limit.enum';

export class Voucher {
  id?: number;
  name?: string;
  ticketLimitType?: TicketLimitType;
  limit?: number | null;
  numberVouchersApplied?: number;
  discount?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  tickets?: EventTicket[];
  isPublic?: boolean;

  constructor(json: ClassData<Voucher>) {
    this.id = json.id;
    this.name = json.name;
    this.ticketLimitType = json.ticketLimitType;
    this.limit = json.limit;
    this.numberVouchersApplied = json.numberVouchersApplied;
    this.discount = json.discount;
    this.startDate = json.startDate;
    this.endDate = json.endDate;
    if (json.tickets) {
      this.tickets = json.tickets.map((x) => new EventTicket(x));
    }
    this.isPublic = json.isPublic;
  }
}
