@if(order){
  <div class="request-refund-dialog-content" mat-dialog-content>
    <div (click)="close()" class="close-button">
      <i class="fal fa-times-circle"></i>
    </div>
    <div class="dialog-body">
      <div class="dialog-title">
        {{ "APP.REQUEST_REFUND.REQUEST_REFUND" | translate }}
      </div>
      <div>
      @if(step === 2){
        <div class='confirm-wrapper'>
          <i class="far fa-info-circle"></i>
          <div>
            {{ "APP.REQUEST_REFUND.REQUEST_REFUND_CONFIRMATION" | translate }}
          </div>
        </div>
      }
      </div>
      <div class="refund-wrapper">
        <div class='form-title'> {{ "APP.REQUEST_REFUND.ORDER_NUMBER" | translate }}</div>
        <div>{{ order.orderNumber }}</div>
        <div>
          @if(order.eventAttendeeTickets && order.eventAttendeeTickets.length > 0){
            {{ order.eventAttendeeTickets.length }}
            @if(order.eventAttendeeTickets.length > 1){
              {{ "APP.REQUEST_REFUND.TICKETS" | translate }}
            }@else{
              {{ "APP.REQUEST_REFUND.TICKET" | translate }}
            }
            - {{ order.getDisplayTotalPrice()}}
          }
          </div>
      </div>
      <div class="refund-wrapper">
        <div class='form-title'> {{ "APP.REQUEST_REFUND.CONTACT_INFORMATION" | translate }}</div>
        <div class='row'>
          <div class='cell'>
            <div class='cell-title'> {{ "APP.REQUEST_REFUND.YOUR_NAME" | translate }}</div>
            <div>{{ loggedUser?.getDisplayName() }}</div>
          </div>
          <div class='cell'>
            <div class='cell-title'> {{ "APP.REQUEST_REFUND.EMAIL_ADDRESS" | translate }}</div>
            <div> {{ loggedUser?.email }}</div>
          </div>
        </div>
      </div>

      @if(form && step === 1){
        <form [formGroup]="form">
          <div class="form-title">
            {{ "APP.REQUEST_REFUND.REFUND_REASON" | translate }}*
          </div>

          <mat-form-field class="flex-input w-100" appearance="outline">
            <mat-label class="placeholder">
              {{ "APP.REQUEST_REFUND.REFUND_REASON" | translate }}*
             </mat-label>
            <mat-select
              formControlName='refundReason'
              (selectionChange)="changeRefundReason($event)"
            >
              <mat-option
                *ngFor="let reason of RefundReason.getAllRefundReasons()"
                [value]="reason"
              >
                {{ RefundReason.getDisplayName(reason) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="form-title">
            {{ "APP.REQUEST_REFUND.MESSAGE" | translate }} *
          </div>
          <mat-form-field appearance="outline" class="w-100">
        <textarea
          #message
          cdkTextareaAutosize
          matInput
          formControlName="message"
          maxlength="128"
          placeholder="Message"
        ></textarea>
            <span matTextSuffix class='charsCount'>{{ message.value.length || "" }} | 128</span>
          </mat-form-field>
        </form>

      }
      @if(form && step === 2){
        <div class="refund-wrapper">
          <div class='form-title'> {{ "APP.REQUEST_REFUND.REFUND_REASON" | translate }}</div>
          <div>{{ form.controls['refundReason'].value || ''}}</div>
        </div>
        <div class="refund-wrapper">
          <div class='form-title'>  {{ "APP.REQUEST_REFUND.MESSAGE" | translate }}</div>
          <div>{{ form.controls['message'].value || ''}}</div>
        </div>
      }
    </div>
    <div class='dialog-actions'>
      @if(step === 1){
        <button class='btn btn-primary-outline' (click)='close()'> {{ "APP.CANCEL" | translate }} </button>
        <button class='btn btn-primary' (click)='goNext()'> {{ "APP.CONTINUE" | translate }} </button>
      }@else{
        <button class='btn btn-primary-outline' (click)='goBack()'> {{ "APP.REQUEST_REFUND.EDIT_DATA" | translate }} </button>
        <button class='btn btn-primary' (click)='sendRefundRequest()'> {{ "APP.REQUEST_REFUND.REQUEST_REFUND" | translate }} </button>
      }

    </div>
  </div>
}
