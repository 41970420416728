import { DatePipe, UpperCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CreateEventTicketOrderSessionTicket } from '@models/events/dto/create-event-ticket-order-session.request';
import { EventTicket } from '@models/events/event-ticket.model';
import { TicketVoucherCodeResponse } from '@models/tickets/dto/ticket-voucher-code.response';
import { TicketFeeType } from '@models/tickets/ticket-fee-type.enum';
import { TranslateModule } from '@ngx-translate/core';
import { NumberUtils } from '@utils/number-utils';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'app-event-ticket-selection-view',
  standalone: true,
  imports: [DatePipe, UpperCasePipe, TranslateModule, QuillEditorComponent],
  templateUrl: './event-ticket-selection-view.component.html',
  styleUrl: './event-ticket-selection-view.component.scss',
})
export class EventTicketSelectionViewComponent implements OnInit, OnChanges {
  @Input() ticket?: EventTicket;
  @Input() squaredStyle?: boolean = false;
  @Input() ticketQuantity?: number = 0;
  @Input() orderTickets: CreateEventTicketOrderSessionTicket[] = [];
  @Input() isSoldOut?: boolean = false;
  @Input() appliedDiscount: boolean = false;
  @Input() appliedVoucher?: TicketVoucherCodeResponse;
  @Input() ticketsWithAppliedDiscount?: number[] = [];
  @Input() currentTicketQuantity = 0;
  @Output() addTicket = new EventEmitter<EventTicket>();
  @Output() removeTicket = new EventEmitter<EventTicket>();
  isExpanded = false;
  isLimitReached = false;
  removeDisabled: boolean = false;

  ngOnInit(): void {
    if (this.orderTickets) this.isLimitReached = this.discountLimitReached();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isLimitReached = this.discountLimitReached();

    if (
      this.appliedDiscount &&
      this.appliedVoucher &&
      this.appliedVoucher.limit &&
      (this.currentTicketQuantity === this.appliedVoucher.limit ||
        this.currentTicketQuantity > this.appliedVoucher.limit)
    ) {
      this.isLimitReached = true;
    }
  }

  onAdd() {
    if (this.ticket) {
      if (!this.ticket.id) {
        return;
      }
      if (this.checkTicketAddValid(this.ticket)) {
        this.addTicket.emit(this.ticket);
      }
    }
  }

  calculateDiscount() {
    let price = this.ticket?.price ?? 0;
    if (this.appliedVoucher) {
      if (this.appliedVoucher.discountFixedPrice) {
        price -= this.appliedVoucher.discountFixedPrice;
        if (price < 0) {
          price = 0;
        }
      } else {
        const discountPercentage = this.appliedVoucher.discountPercentage ?? 0;
        price *= 1 - discountPercentage / 100;
      }
    }
    return `${NumberUtils.roundToUpTo2Decimals(price / 100)} €`;
  }

  isBeforeStartSale(): boolean {
    if (this.ticket) {
      const today = new Date();

      return this.ticket.saleStartDate
        ? today < this.ticket.saleStartDate
        : false;
    } else return false;
  }

  onRemove() {
    this.removeTicket.emit(this.ticket);
  }

  isMinAmountBiggerThanVoucherLimit() {
    if (this.appliedVoucher && this.appliedDiscount) {
      if (
        this.ticket?.minAmountPerOrder &&
        this.appliedVoucher.limit &&
        this.ticket?.minAmountPerOrder > this.appliedVoucher.limit
      ) {
        return true;
      }
    }
    return false;
  }
  toggleReadMore(): void {
    this.isExpanded = !this.isExpanded;
  }

  discountLimitReached() {
    // TODO: if no tickets set on voucher (all tickets) then get qty of all order tickets or get sum of all set tickets, do not take into account tickets which the voucher is not for
    if (this.appliedVoucher && this.appliedDiscount) {
      const existingTicketIdx = this.orderTickets.findIndex(
        (orderTicket) => orderTicket.eventTicketId === this.ticket?.id,
      );
      let allQuantity = 0;
      if (existingTicketIdx !== -1) {
        if (this.ticketsWithAppliedDiscount) {
          if (this.ticketsWithAppliedDiscount.length === 0) {
            allQuantity = this.orderTickets
              .map((orderTicket) => orderTicket.quantity)
              .reduce((sum, quantity) => sum + quantity, 0);
          } else {
            allQuantity = this.orderTickets
              .filter(
                (ticket) =>
                  this.ticketsWithAppliedDiscount?.includes(
                    ticket.eventTicketId,
                  ),
              )
              .map((orderTicket) => orderTicket.quantity)
              .reduce((sum, quantity) => sum + quantity, 0);
          }
        }
      }

      const currentOrderTicket = this.orderTickets.find(
        (order) => order.eventTicketId === this.ticket?.id,
      );
      if (
        this.appliedVoucher.limit &&
        (this.appliedVoucher.limit < allQuantity ||
          this.appliedVoucher.limit === allQuantity ||
          (this.ticket?.minAmountPerOrder &&
            currentOrderTicket &&
            currentOrderTicket.quantity === 0 &&
            this.ticket.minAmountPerOrder + allQuantity >
              this.appliedVoucher.limit))
      ) {
        return true;
      }
    }
    return false;
  }

  checkTicketAddValid(ticket: EventTicket): boolean {
    const existingTicketIdx = this.orderTickets.findIndex(
      (orderTicket) => orderTicket.eventTicketId === this.ticket?.id,
    );
    let currentQuantity = 0;
    if (existingTicketIdx !== -1) {
      if (ticket.minAmountPerOrder && this.appliedDiscount) {
        currentQuantity =
          this.orderTickets[existingTicketIdx].quantity +
          ticket.minAmountPerOrder;
      } else {
        currentQuantity = this.orderTickets[existingTicketIdx].quantity + 1;
      }
    } else {
      if (ticket.minAmountPerOrder && this.appliedDiscount) {
        currentQuantity = ticket.minAmountPerOrder;
      } else {
        currentQuantity = 1;
      }
    }

    const numAvailable = ticket.getNumAvailableTickets();

    if (
      currentQuantity > numAvailable ||
      (ticket.maxAmountPerOrder && currentQuantity > ticket.maxAmountPerOrder)
    ) {
      return false;
    }

    return true;
  }

  protected readonly TicketFeeType = TicketFeeType;
}
