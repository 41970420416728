import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { environment as env } from '@environments/environment';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventAttendeeTicket } from '@models/events/event-attendee-ticket.model';
import { Event } from '@models/events/event.model';
import { TicketAction } from '@models/tickets/ticket-action.enum';
import { User } from '@models/users/user.model';
import { TicketActionComponent } from '@modules/events/dialogs/get-bought-tickets/components/ticket-action/ticket-action.component';
import { RequestRefundDialog } from '@modules/events/dialogs/request-refund/request-refund.dialog';
import { RequestedRefundOverviewDialog } from '@modules/events/dialogs/requested-refund-overview/requested-refund-overview.dialog';
import { QrDataCardComponent } from '@modules/shared/components/qr-data-card/qr-data-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { QRCodeModule } from 'angularx-qrcode';
import { SwiperDirective } from 'app/directives/swiper.directive';
import { SwiperOptions } from 'swiper/types';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    QRCodeModule,
    MatDialogActions,
    QrDataCardComponent,
    MatDialogContent,
    SwiperDirective,
    TicketActionComponent,
  ],
  templateUrl: './get-bought-tickets.dialog.html',
  styleUrl: './get-bought-tickets.dialog.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EventService, NotificationService, TranslateService],
})
export class GetBoughtTicketsDialog implements AfterViewInit {
  tickets: EventAttendeeTicket[] = [];
  selectedTicket?: EventAttendeeTicket;
  loggedUser?: User;
  event?: Event;
  order?: EventAttendeeTicketOrder;
  @ViewChild('swiperContainer') swiperContainer!: any;
  @ViewChild('swiperRef', { static: false }) swiperRef: any;
  @ViewChild('swiperElement') swiperElement: any;
  constructor(
    public dialogRef: MatDialogRef<GetBoughtTicketsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.event = data.event;
      this.order = data.order;
      if (data.order) {
        data.order.eventAttendeeTickets.forEach(
          (ticket: EventAttendeeTicket) => {
            this.tickets.push(ticket);
          },
        );
        this.selectedTicket = this.tickets[0];
      }
      this.loggedUser = data.loggedUser;
    }
  }

  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 2000,
    spaceBetween: 16,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
    },
    injectStyles: [
      `  :host .swiper { overflow: visible;  min-height: 500px;}
      .swiper-wrapper {
        min-height: 500px !important;
      }
      .swiper-pagination-bullets-dynamic {
        bottom: 15px !important;
      }
    `,
    ],
  };

  swiperActionsConfig: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 2000,
    spaceBetween: 1,
    injectStyles: [':host .swiper { overflow: visible !important;}'],
  };

  ngAfterViewInit(): void {
    const swiperEl = this.swiperContainer.el.nativeElement.swiper;
    swiperEl.on('slideChange', () => {
      this.selectedTicket = this.tickets[swiperEl.realIndex];
    });
  }

  requestRefund() {
    if (this.order?.refund) {
      const dialogRef = this.dialog.open(RequestedRefundOverviewDialog, {
        maxWidth: '602px',
        maxHeight: '100vh',
        width: '100%',
        height: 'auto',
        data: {
          event: this.event,
          order: this.order,
          loggedUser: this.loggedUser,
        },
        panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
      });
    } else {
      const dialogRef = this.dialog.open(RequestRefundDialog, {
        maxWidth: '602px',
        maxHeight: '100vh',
        width: '100%',
        height: 'auto',
        autoFocus: false,
        disableClose: true,
        data: {
          event: this.event,
          order: this.order,
          loggedUser: this.loggedUser,
        },
        panelClass: ['normal-dialog'],
      });
    }
  }

  getHeaderText() {
    return `${this.translateService.instant(
      'APP.TICKET_ACTIONS.TICKET_FROM',
    )} ${this.loggedUser?.name}`;
  }

  addToWallet() {
    if (!this.selectedTicket) {
      return;
    }
    this.eventService
      .getEventTicketWalletPass(this.selectedTicket.uuid)
      .subscribe({
        next: (passPageLink: string) => {
          window.open(passPageLink, '_blank');
        },
        error: () => {
          this.notificationService.error(
            this.translateService.instant(
              'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
            ),
          );
        },
      });
  }

  shareTicket() {
    if (this.order && this.selectedTicket) {
      this.eventService
        .getTicketOrderPdf(
          this.order.uuid,
          `${this.selectedTicket.ticketNumber}.pdf`,
          this.selectedTicket.uuid,
        )
        .subscribe({
          next: (blobWithFileName) => {
            const rawBlob =
              blobWithFileName instanceof Blob
                ? blobWithFileName
                : blobWithFileName.blob;

            if (!rawBlob || rawBlob.size === 0) {
              this.notificationService.error(
                'The ticket file is empty or invalid.',
              );
              return;
            }

            const file = new File(
              [rawBlob],
              `${this.selectedTicket?.ticketNumber}.pdf`,
              { type: 'application/pdf' },
            );

            if (navigator.canShare({ files: [file] })) {
              navigator.share({
                title: 'Your Event Ticket',
                text: 'Here is your ticket for the event!',
                files: [file],
              });
            } else {
              this.notificationService.error(
                'Sharing is not supported on this browser.',
              );
            }
          },
          error: () => {
            this.notificationService.error(
              'Failed to download the ticket for sharing.',
            );
          },
        });
    } else {
      this.notificationService.error('Invalid ticket details.');
    }
  }

  sendTicket() {
    const subject = encodeURIComponent('Send Ticket');
    const body = encodeURIComponent('Please find the ticket attached.');
    const email = 'support@eventpage.ai';

    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
  }

  executeAction(action: TicketAction) {
    switch (action) {
      case TicketAction.REFUND:
        this.requestRefund();
        break;
      case TicketAction.ADD_TO_WALLET:
        this.addToWallet();
        break;
      case TicketAction.SHARE:
        this.shareTicket();
        break;
      case TicketAction.DOWNLOAD:
        this.downloadTicket();
        break;
      case TicketAction.SEND:
        this.sendTicket();
        break;
    }
  }

  downloadTicket() {
    if (this.order && this.selectedTicket) {
      this.eventService
        .downloadTicketOrderPdf(
          this.order.uuid,
          `${this.selectedTicket.ticketNumber}.pdf`,
          this.selectedTicket.uuid,
        )
        .subscribe({
          error: () => {
            this.notificationService.error(
              this.translateService.instant(
                'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
              ),
            );
          },
        });
    }
  }

  close() {
    this.dialogRef.close();
  }

  protected env = env;
  protected readonly TicketAction = TicketAction;
}
